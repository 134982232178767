import React, { useContext, useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { callAPI } from "../../helper/apiUtils";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import $ from "jquery";
import {
  FORWARD_MESSAGE,
  GROUPS,
  GROUPS_BOTTOM,
  MESSAGE_IDS,
  OPEN_BOTTOM_CHATS,
  OPEN_CHATS,
  UPDATE_GROUP_MESSAGE,
  DRAFT_MESSAGES,
  IS_OPEN_BY_OWNER,
  ALL_BADGE_COUNT,
} from "../../store";
import { connect, useSelector } from "react-redux";
import { ReactComponent as AddOneToOne } from "../../Icons/AddOneToOne.svg";
import AddOneToOneUser from "./AddOneToOneUser";
import { AiOutlineFile, BsFillPlusCircleFill, IoMdImage, MdVideocam } from "react-icons/all";
import { $crud } from "../../helper/CrudFactory";
import { $user } from "../../helper/UserFactory";
import _ from "lodash";
import { Rings } from "react-loader-spinner";
import { SocketContext } from "../../context/socket";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function Chat(props) {
  const user = $user.get();
  const { isGerm } = React.useContext(LanguageContext);
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [chatUser, setChatUser] = useState(false);
  const [action, setAction] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [storedDraftMessage, setStoredDraftMessage] = useState([]);
  const { unSeenAllCount } = useSelector((state) => state);
  const [deUSer, setDEUser] = useState([]);
  const [getting, setGetting] = useState(false);
  const Socket = useContext(SocketContext);
  const [viewLoader, setViewLoader] = useState(false);

  const get = async () => {
    setGroups([]);
    setDEUser([]);
    setGetting(true);
    const { data } = await callAPI("/group", {}, "GET");
    if (data.status) {
      setGetting(false);
      const updateData = data.groups.map((building, i) => {
        return {
          ...building,
          key: i + 1,
          isOpened: !![...props.openChats, ...props.openBottomChats].find(
            (g) => g.id === building.id
          ),
          originalMessage: building.lastMessage,
          messageLng: "en",
          isBlock: building.name.trim()
            ? !!building.users.find((u) => u.id !== user?.id)?.isBlock
            : false,
        };
      });
      props.updateGroups(updateData);
    }
  };
  useEffect(() => {}, []);

  useEffect(() => {
    get();
  }, []);
  useEffect(() => {
    let _Temp = [...props.groups];
    let All = [...props.groups];
    let isDeactivatedU = [];
    for (let obj of _Temp) {
      if (obj.users) {
        const chatUser = obj.users.filter((u) => u.id !== user?.id);
        if (chatUser) {
          if (chatUser[0]?.isDeactivated) {
            obj.isDeactivated = true;
            isDeactivatedU.push(obj);
            // let ind = _Temp.findIndex(e => e.id == obj.id)
            // All.splice(ind, 1)
          } else {
            obj.isDeactivated = false;
          }
        }
      }
    }
    const localStoredMessage = JSON.parse(localStorage.getItem("draftMessage"))
      ? JSON.parse(localStorage.getItem("draftMessage"))
      : [];
    setStoredDraftMessage(localStoredMessage);
    props.updateDraftMessages(localStoredMessage);
    if (props.groups.length < 1) {
    } else {
      let final = [];
      for (let obj of props.groups) {
        let ind = isDeactivatedU.findIndex((e) => e.id == obj.id);
        if (ind == -1) {
          final.push(obj);
        }
      }
      const groupList = _.uniqBy(final, "id");
      setGroups(groupList);
      setDEUser(isDeactivatedU);
      setAllGroups(groupList);
    }
  }, [props.groups]);

  //Search Project
  const searchUser = async (e) => {
    const value = e.target.value.trim();
    setSearch(value);
    const searchResult = await allGroups.filter((group) => {
      const chatUser = group.users.filter((u) => u.id !== user.id);
      let str1 = chatUser[0].firstName;
      let str2 = chatUser[0].lastname;
      if (str1.toLowerCase().startsWith(value.toLowerCase())) {
        return true;
      } else if (str2.toLowerCase().startsWith(value.toLowerCase())) {
        return true;
      } else {
        let words = value.toLowerCase()?.split(" ");
        if (words.length >= 2) {
          let name =
            str1.toLowerCase().trim() + " " + str2.toLowerCase().trim();
          if (name.toLowerCase().includes(value.toLowerCase())) return true;
          else return false;
        }
      }
    });
    if (!value) {
      setGroups(allGroups);
    } else {
      setGroups(searchResult);
    }
  };

  const setDraftMessage = (message, group) => {
    message = message.trim();
    if (message) {
      const msgObject = {
        userId: user?.id,
        message: message,
        messageType: "simple",
        group: group.id,
      };

      let allMessages = props.draftMessages;
      const foundIndex = allMessages.findIndex(
        (msg) => msg.userId === user?.id && msg.group === group.id
      );
      console.log(foundIndex);
      if (foundIndex > -1) {
        console.log(allMessages[foundIndex]);
        allMessages[foundIndex] = {
          ...allMessages[foundIndex],
          message: message,
        };
      } else {
        allMessages = [...allMessages, msgObject];
      }
      console.log(allMessages);
      localStorage.setItem("draftMessage", JSON.stringify(allMessages));
      props.updateDraftMessages(allMessages);
    }
  };

  const chat_click = async (group, index) => {
    // console.log("props.openChats",props.openChats)
    let a = { ...unSeenAllCount };
    let f = {
      ...a,
      unseenChatCount:
        a.unseenChatCount - group.unseenChatCount < 0
          ? 0
          : a.unseenChatCount - group.unseenChatCount,
    };
    await props.updateAllBadgeCount(f);
    let tmp = [...groups];
    if (tmp[index]) tmp[index].unseenChatCount = 0;
    setAllGroups(tmp);
    setGroups(tmp);
    props.isOwnerOpenProject(false);
    const chk = props.openChats.find((g) => g.id === group.id);
    if (chk) {
      setDraftMessage($(`.textInput-${group.id}`).text(), group);
      const openGroups = props.openChats.filter((p) => p.id !== group.id);
      const bottomGroup = await groups.map((p) => {
        if (p.id === group.id) {
          return { ...p, isOpened: false };
        } else {
          return { ...p };
        }
      });
      console.log("openGroups", openGroups);
      if (openGroups?.length > 2) {
        $crud.notify({
          type: "error",
          message: isGerm ? DE.more_than_two : EN.more_than_two,
        });
      } else {
        setGroups(bottomGroup);
        setAllGroups(bottomGroup);
        props.updateGroups(bottomGroup);
        props.updateOpenChats(openGroups);
      }
    } else {
      let tempData = [...props.openChats, group];
      if (tempData?.length > 2) {
        $crud.notify({
          type: "error",
          message: isGerm ? DE.more_than_two : EN.more_than_two,
        });
        console.log("greter than 2");
      } else {
        props.updateOpenChats([...props.openChats, group]);
        const updateGroup = await groups.map((g) => {
          if (g.id === group.id) {
            return { ...g, isOpened: true };
          } else {
            return { ...g };
          }
        });
        setGroups(updateGroup);
        setAllGroups(updateGroup);
        props.updateGroups(updateGroup);
      }
    }
  };

  useEffect(() => {
    if (props.openChats.length > 0) {
      const updateGroup = groups.map((g) => {
        const chk = props.openChats.find((c) => c.id === g.id);
        if (chk) {
          return { ...g, isOpened: true };
        } else {
          return { ...g };
        }
      });
      if (updateGroup.length < 1) {
        get();
      } else {
        setGroups(updateGroup);
        setAllGroups(updateGroup);
      }
    }
  }, [props.openChats]);

  const handleUserAction = () => {
    setAction("top");
    setChatUser(true);
  };

  const handleForward = async () => {
    const countGroups = selectedGroups.length;
    if (countGroups > 0) {
      setViewLoader(true);
      const { data } = await callAPI("/message/forward", {}, "POST", {
        group: selectedGroups,
        id: props.messageIds,
      });
      if (data.status) {
        $crud.notify({
          type: "success",
          message: "Message forwarded!",
        });
      }
      props.updateForwardMessage(false);
      props.updateMessageIds([]);
      await selectedData?.map((itm) => chat_click(itm));
      await setSelectedData([]);
      await setSelectedGroups([]);
      setViewLoader(false);
    } else {
      $crud.notify({
        type: "error",
        message: "Please select group to forward message",
      });
    }
  };
  const handleCancel = () => {
    props.updateForwardMessage(false);
    props.updateOpenBottomChats([]);
  };

  const selectIds = (id, group) => {
    const chk = selectedGroups.find((g) => g === id);
    if (!chk) {
      const ids = [...selectedGroups, id];
      setSelectedGroups(ids);
      const data = [...selectedData, group];
      setSelectedData(data);
    } else {
      const ids = selectedGroups.filter((g) => g !== id);
      const datas = selectedData.filter((g) => g?.id !== group?.id);
      setSelectedGroups(ids);
      setSelectedData(datas);
    }
  };

  useEffect(() => {
    if (props.updateGroupMessage.message === "") {
      console.log("updating from groupMessages");
      get();
    } else {
      if (typeof props.updateGroupMessage.message != "undefined") {
        let groupsData = groups.map((g) => {
          if (g.id === props.updateGroupMessage.group) {
            g.lastMessage = props.updateGroupMessage.message;
            g.originalMessage = props.updateGroupMessage.message;
            g.lastMessageType = props.updateGroupMessage.messageType;
            g.lastMessageDeleted = props.updateGroupMessage.lastMessageDeleted;
          }
          return g;
        });
        Promise.resolve().then(() => {
          setGroups(groupsData);
          setAllGroups(groupsData);
        });
      }
    }
  }, [props.updateGroupMessage]);

  useEffect(() => {
    setStoredDraftMessage(props.draftMessages);
  }, [props.draftMessages]);
  return (
    <React.Fragment>
      <div
        className="w-300 my_project py-0 h45vh immoanimation-enter-done"
        style={
          props.isOpenByOwner
            ? { pointerEvents: "none", opacity: "0.2", height: "93vh" }
            : { height: "93vh", display: "flex", flexDirection: "column" }
        }
      >
        {viewLoader && (
          <div className="website_loader">
            <div className="loader" />
          </div>
        )}
        {getting && (
          <div
            style={{
              position: "absolute",
              height: "93vh",
              width: "100%",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: "flex",
            }}
          >
            <div style={{ flex: 1, paddingTop: "10vw", paddingLeft: "10vw" }}>
              <Rings
                height="100"
                width="100"
                color="#f08438"
                radius="6"
                wrapperStyle={{ flex: 1 }}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
              />
            </div>
          </div>
        )}
        {!chatUser && (
          <div style={{ flex: 1 }}>
            <div className="main_heading">
              {isGerm ? DE.Chats : EN.Chats}
              {/* <AddOneToOne
                title="Create one-on-one chat"
                onClick={handleUserAction}
                className="svg_active pointer"
                size={20}
              /> */}
              <BsFillPlusCircleFill size={25} color={KJUUP_COLORS.base_color} onClick={handleUserAction}/>
            </div>
            <div className="search">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control
                  autocomplete="off"
                  type="search"
                  onChange={(e) => searchUser(e)}
                  placeholder={isGerm ? DE.search : EN.search}
                />
              </Form.Group>
              <img
                src={process.env.PUBLIC_URL + "/assets/img/search.svg"}
                alt="Generic placeholder"
              />
            </div>
          </div>
        )}

        {!chatUser && !getting && (
          <CustomScrollbars
            autoHide
            autoHideTimeout={200}
            autoHideDuration={200}
            className="bottom-bar"
          >
            <div
              className="company_services m-0 chat_services"
              style={{ flex: 1 }}
            >
              {groups?.map((group, index) => {
                let isBlock = false;
                let isDeativated = false;

                const chatUser = group.users.filter((u) => u.id !== user?.id);
                if (chatUser) {
                  if (chatUser[0]) {
                    isBlock = chatUser[0]?.isBlock;
                  }
                  if (chatUser[0]) {
                    isDeativated = !chatUser[0].isActive;
                  }
                }
                return (
                  <div
                    key={index}
                    className={`company_centact ${
                      group.isOpened ? "active-project" : ""
                    }`}
                    onClick={(e) => {
                      if (!props.forwardMessage) chat_click(group, index);
                    }}
                    style={{ opacity: group?.isDeactivated ? 0.5 : 1 }}
                  >
                    <a className="website pointer">
                      <div className="website_img">
                        <img
                          width={40}
                          height={40}
                          style={{ borderRadius: "5px" }}
                          src={
                            group.avatar
                              ? BASE_URL + group.avatar
                              : process.env.PUBLIC_URL +
                                "/assets/img/profile.png"
                          }
                          alt="Generic placeholder"
                        />
                        {group.unseenChatCount > 0 ? (
                          <Badge pill>{group.unseenChatCount}</Badge>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="details-company forword-checkbox_main">
                        <div className="m-0">
                          <h5>{group.name}</h5>
                          {!isBlock &&
                            !isDeativated &&
                            !storedDraftMessage.find(
                              (m) => m.group === group.id
                            ) && (
                              <div className="m-0">
                                {group.lastMessageDeleted ? (
                                  <p className="m-0 text-truncate">
                                    {isGerm
                                      ? DE.message_delete
                                      : EN.message_delete}
                                  </p>
                                ) : (
                                  group.lastMessageType === "simple" && (
                                    <p className="m-0 text-truncate">
                                      {group.lastMessage?.replaceAll(
                                        "<br/>",
                                        " "
                                      )}
                                    </p>
                                  )
                                )}
                                {group.lastMessageType === "doc" &&
                                  !group.lastMessageDeleted && (
                                    <p className="m-0 text-truncate">
                                      <AiOutlineFile />
                                    </p>
                                  )}
                                {group.lastMessageType === "video" &&
                                  !group.lastMessageDeleted && (
                                    <p className="m-0 text-truncate">
                                      <MdVideocam /> {group.lastMessage}
                                    </p>
                                  )}
                                {group.lastMessageType === "image" &&
                                  !group.lastMessageDeleted && (
                                    <p className="m-0 text-truncate">
                                      <IoMdImage />
                                    </p>
                                  )}
                              </div>
                            )}
                          {storedDraftMessage.find(
                            (m) => m.group === group.id
                          ) &&
                            !isBlock && (
                              <div className="draft-message m-0">
                                <p>
                                  [draft]{" "}
                                  {
                                    storedDraftMessage.find(
                                      (m) => m.group === group.id
                                    )?.message
                                  }
                                </p>
                              </div>
                            )}
                          {isBlock && (
                            <p className="m-0 text-truncate">
                              You have blocked this contact
                            </p>
                          )}
                          {isDeativated && (
                            <p className="m-0 text-truncate">
                              {isGerm ? DE.a_Da : EN.a_Da}
                            </p>
                          )}
                        </div>
                        {props.forwardMessage &&
                          group.id !== props.forOpenChatId &&
                          !isDeativated && (
                            <div className="forward-checkbox">
                              <label className="container_checkbox">
                                <input
                                  type="checkbox"
                                  onClick={(e) => {
                                    selectIds(group.id, group);
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          )}
                      </div>
                    </a>
                  </div>
                );
              })}
              {deUSer &&
                !search &&
                deUSer.map((group, index) => (
                  <div
                    key={index}
                    className={`company_centact ${
                      group.isOpened ? "active-project" : ""
                    }`}
                    onClick={(e) => chat_click(group)}
                    style={{ opacity: group?.isDeactivated ? 0.5 : 1 }}
                  >
                    <a className="website pointer">
                      <div className="website_img">
                        <img
                          width={40}
                          height={40}
                          style={{ borderRadius: "5px" }}
                          src={
                            group.avatar
                              ? BASE_URL + group.avatar
                              : process.env.PUBLIC_URL +
                                "/assets/img/profile.png"
                          }
                          alt="Generic placeholder"
                        />
                        {group.unseenChatCount > 0 ? (
                          <Badge pill>{group.unseenChatCount}</Badge>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="details-company forword-checkbox_main">
                        <div className="m-0">
                          <h5>{group.name}</h5>
                          <p className="m-0 text-truncate">
                            {isGerm ? DE.account_deleted : EN.account_deleted}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </CustomScrollbars>
        )}

        {!chatUser && (
          <div>
            {props.forwardMessage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 15,
                }}
              >
                <div
                  onClick={handleCancel}
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 10,
                    border: "2px solid #000",
                    padding: 10,
                    borderRadius: 8,
                    marginRight: 10,
                  }}
                >
                  <a
                    onClick={handleCancel}
                    className=""
                    href="#"
                    style={{ fontSize: 15, fontWeight: "700", color: "#000" }}
                  >
                    {isGerm ? DE.cancel : EN.cancel}
                  </a>
                </div>
                <div
                  nClick={handleForward}
                  style={{
                    flex: 1,
                    textAlign: "center",
                    border: "2px solid",
                    padding: 10,
                    borderRadius: 8,
                    color: KJUUP_COLORS.base_color,
                  }}
                >
                  <a
                    onClick={handleForward}
                    className="text-orange"
                    href="#"
                    style={{ fontSize: 15, fontWeight: "700" }}
                  >
                    {isGerm ? DE.forward : EN.forward}
                  </a>
                </div>
              </div>
            )}
          </div>
        )}

        {chatUser && (
          <AddOneToOneUser
            action={action}
            hide={() => setChatUser(false)}
            afterAddUser={(data) => {
              get();
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    groups,
    groupsBottom,
    openChats,
    openBottomChats,
    forwardMessage,
    messageIds,
    updateGroupMessage,
    language,
    draftMessages,
    isOpenByOwner,
    forOpenChatId,
  } = state;
  return {
    groups: groups,
    language: language,
    groupsBottom: groupsBottom,
    openChats: openChats,
    openBottomChats: openBottomChats,
    forwardMessage: forwardMessage,
    messageIds: messageIds,
    updateGroupMessage: updateGroupMessage,
    draftMessages: draftMessages,
    isOpenByOwner,
    forOpenChatId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateGroupsBottom: (groups) =>
      dispatch({ type: GROUPS_BOTTOM, groupsBottom: groups }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
    updateForwardMessage: (value) =>
      dispatch({ type: FORWARD_MESSAGE, forwardMessage: value }),
    updateMessageIds: (ids) => dispatch({ type: MESSAGE_IDS, messageIds: ids }),
    updateLastMessage: (msg) =>
      dispatch({ type: UPDATE_GROUP_MESSAGE, updateGroupMessage: msg }),
    updateDraftMessages: (msg) =>
      dispatch({ type: DRAFT_MESSAGES, draftMessages: msg }),
    isOwnerOpenProject: (open) => dispatch({ type: IS_OPEN_BY_OWNER, open }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat));
