import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, CloseButton } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import { BsChevronUp, BsChevronDown, BsSearch, BsX } from "react-icons/bs";
import { useState } from 'react';
import Layout from '../../components/Layout';
import LanguageContext from '../../helper/LanguageContext';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { callAPI } from "../../helper/apiUtils";
import _ from "lodash";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { convertPosition } from '../../helper/convertor';
import { BiAdjust, BiBell, BiExit, BiX, BiXCircle } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';
import { $user } from '../../helper/UserFactory';
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux';

function ProjectSettings(props) {
    const user = useSelector((state) => state.user);
    const { isGerm } = React.useContext(LanguageContext);
    const [display, setDisplay] = useState("0")
    const [company, setCompany] = useState({})
    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [admins, setAdmins] = useState([])
    const [allAdmins, setAllAdmins] = useState([])
    const [search, setSearch] = useState('')
    const [dashboard, setDashboard] = useState({})
    const [setting, setSetting] = useState({})
    const history = useHistory();
    const [totalAdmins, setToalAdmins] = useState(0)
    const [viewLoader, setViewLoader] = useState(false)
    const [departmentPersons, setdepPersons] = useState([])

    const handleClass = (index) => {
        if (display === index) {
            setDisplay(null)
        } else {
            setDisplay(index)

        }
    }

    useEffect(() => {
        setAdmins(props.members)
        setAllAdmins(props.members)
        if (props.members?.length > 0) {
            let tadmins = []
            props.members.map((e, z) => {
                e.names.map((j, k) => {
                    if (j.buldingAdmin) {
                        tadmins.push(j.id)
                    }
                })
            })
            setToalAdmins(tadmins.length)
        }
        console.log(props.members)
    }, [props.members])

    const getDashboard = async () => {
        const id = props.match.params.id
        const { data } = await callAPI(`/company/${id}/dashboard`, {}, 'GET')
        await setDashboard(data.dashboard)
    }
    const getSetting = async () => {
        const id = props.match.params.id
        const { data } = await callAPI(`/company/${id}/setting`, {}, 'GET')
        await setSetting(data)
    }

    const getCompanyDetails = async () => {
        const id = props.match.params.id
        const { data } = await callAPI(`/company/${id}/profile`, {}, 'GET')
        if (data.status) {
            await setCompany(data.profile)
            getCompanyAdmins(data.profile);
            getEmployeesDept(data.profile)
        }
    }
    const getCompanyAdmins = async (profile) => {
        const id = props.match.params.id
        const { data } = (props.match.params.deptId && profile?.role != "ROLE_COMPANY_SUPER_ADMIN") ? await callAPI(`/company/${id}/admin?deptLocation=${props.match.params.deptId}`, {}, 'GET') : await callAPI(`/company/${id}/admin`)
        await CreateAdminList(data.coworker, profile)
    }

    // useEffect(() => {
    //     getDashboard();
    //     getSetting();
    //     getCompanyDetails();
    // }, [props.match.params.id])

    const getEmployeesDept = async (profile) => {
        const id = props.match.params.id;
        const { data } = await callAPI(`/company/${id}/request?deptLocation=${props.match.params.deptId}`, {}, "GET");
        if (data.status && data.members.length > 0) {
            getEmployees(data.members, profile)
        }
        else {
            getEmployees(null, profile);
        }
    };

    const getEmployees = async (depmem, profile) => {
        const id = props.match.params.id
        const { data } = (props.match.params.deptId && profile?.role != "ROLE_COMPANY_SUPER_ADMIN") ? await callAPI(`/company/${id}/coworker?deptLocation=${props.match.params.deptId}`, {}, 'GET') : await callAPI(`/company/${id}/coworker`, {}, 'GET');
        if (profile?.role != "ROLE_COMPANY_SUPER_ADMIN") {
            let usersToFind = []
            if (depmem && depmem.length > 0 && (data.coworkers && data.coworkers.length > 0)) {
                for (let obj of depmem) {
                    for (let objx of data.coworkers) {
                        if (obj.id == objx.id) {
                            usersToFind.push(objx)
                        }
                    }
                }
                await CreateList(usersToFind)
            }
            else {
                setUsers([])
                setAllUsers([])
            }
        }
        else {
            await CreateList(data.coworkers)
        }
    }
    const CreateList = async (users) => {
        const arr = await _.uniqBy(users.map((user) => {
            let name = { name: user.lastName.trim()[0].toUpperCase() }
            return name;
        }), 'name');
        const updatedArr = _.sortBy(arr, ["name"]);
        const usersList = updatedArr.map((a) => {
            let names = users.filter(u => u.lastName.trim()[0].toUpperCase() === a.name)
            names = names.map((e, i) => {
                return { ...e }
            })
            a.names = names;
            return a;
        })
        setUsers([...usersList])
        setAllUsers([...usersList])
    }

    const CreateAdminList = async (admins, profile) => {
        if (admins && admins.length && admins.length > 0) {
            const arr = _.uniqBy(admins.map((user) => {
                let name = { name: user.lastName.trim()[0].toUpperCase() }
                return name;
            }), 'name');
            const updatedArr = _.sortBy(arr, ["name"]);
            let tt = []
            const usersList = updatedArr.map((a) => {
                let names = admins.filter(u => u.lastName.trim()[0].toUpperCase() === a.name)
                names = names.map((e, i) => {
                    return {
                        ...e, buldingAdmin
                            : (e.position == 'EMPLOYEE' || e.position == "COMPANY_DEPARTMENT_ADMIN") ? false : true
                    }
                })
                let tAdmins = names.filter(e => e.buldingAdmin
                )
                tt = [...tt, ...tAdmins]
                a.names = names;
                return a;
            })
            setToalAdmins(tt.length)
            setAdmins([...usersList])
            setAllAdmins([...usersList])
            _makeDep(admins, profile)
        }
    }
    const _makeDep = (admins, profile) => {
        if (admins && admins.length && admins.length > 0) {
            const arr = _.uniqBy(admins.map((user) => {
                let name = { name: user.lastName.trim()[0].toUpperCase() }
                return name;
            }), 'name');
            const updatedArr = _.sortBy(arr, ["name"]);
            let tt = []
            const usersList = updatedArr.map((a) => {
                let names = admins.filter(u => u.lastName.trim()[0].toUpperCase() === a.name)
                names = names.map((e, i) => {
                    return {
                        ...e, buldingAdmin
                            : (e.position == 'EMPLOYEE') ? false : true
                    }
                })
                let tAdmins = names.filter(e => e.buldingAdmin
                )
                tt = [...tt, ...tAdmins]
                a.names = names;
                return a;
            })
            setdepPersons(usersList)
        }
    }
    const filterUsers = async (text) => {
        setSearch(text)
        if (text !== '') {
            let userListFilter = users;
            let positionIndex = await users.findIndex(item => item.name.toLowerCase() == text.toLowerCase());
            if (positionIndex !== -1) {
                console.log(positionIndex);
                userListFilter = [userListFilter[positionIndex]]
                console.log(userListFilter)
            } else {
                let userListFilter = await users.map(item => {
                    console.log(positionIndex);
                    let filterName = item.names.filter(i => i.fullName.toLowerCase().includes(text.toLowerCase()));
                    return { ...item, names: filterName };
                });
                userListFilter = userListFilter.filter(position => position.names.length > 0)
                console.log(userListFilter)
                setUsers(userListFilter)
            }
        } else {
            setUsers(allUsers)
        }
    }
    const filterAdmins = async (searchValue) => {
        setSearch(searchValue)
        searchValue = searchValue.trim();
        if (searchValue) {
            let userListFilter = await allAdmins.map(item => {
                let filterName = item.names.filter(itm => {
                    const fullName = `${itm.firstName.trim()} ${itm.lastName.trim()}`.toLowerCase();
                    if (itm.firstName.toLowerCase().trim().startsWith(searchValue.toLowerCase()) || itm.lastName.trim().toLowerCase().startsWith(searchValue.toLowerCase()) || fullName.toLowerCase().startsWith(searchValue.toLowerCase())) {
                        return true
                    }
                    // const fullName = `${i.firstName}${i.lastName}`.toLowerCase();
                    // const reversedFullName = `${i.firstName}${i.lastName}`.toLowerCase();
                    // const trimmedSearchValue = searchValue.replace(/\s+/g, '').toLowerCase();
                    // return fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue);
                });
                return { ...item, names: filterName };
            });
            userListFilter = userListFilter.filter(position => position.names.length > 0)
            setAdmins(userListFilter)
        }
        else {
            setAdmins(allAdmins)
        }
    }

    const filterAdminsDeps = async (searchValue) => {
        setSearch(searchValue)
        if (searchValue !== '') {
            let userListFilter = admins;
            let positionIndex = await admins.findIndex(item => {
                const fullName = `${item.firstName}${item.lastName}`.toLowerCase();
                const reversedFullName = `${item.firstName}${item.lastName}`.toLowerCase();
                const trimmedSearchValue = searchValue.replace(/\s+/g, '').toLowerCase();
                return fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue);
            });
            if (positionIndex !== -1) {
                userListFilter = [userListFilter[positionIndex]]
            } else {
                let userListFilter = await admins.map(item => {
                    let filterName = item.names.filter(i => {
                        const fullName = `${i.firstName}${i.lastName}`.toLowerCase();
                        const reversedFullName = `${i.firstName}${i.lastName}`.toLowerCase();
                        const trimmedSearchValue = searchValue.replace(/\s+/g, '').toLowerCase();
                        return fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue);
                    });
                    return { ...item, names: filterName };
                });
                userListFilter = userListFilter.filter(position => position.names.length > 0)
                setdepPersons(userListFilter)
            }
        } else {
            setdepPersons(allAdmins)
        }
    }

    const removeUser = async (id, name, i, mainIndex) => {
        await $crud.confirm({
            title: isGerm ? DE.remove_member : EN.remove_member,
            textContent: isGerm ? DE.do_you_really_want_to_remove.replace('username', name) : EN.do_you_really_want_to_remove.replace('username', name),
            options: {
                ok: isGerm ? DE.confirm : EN.confirm,
                cancel: isGerm ? DE.cancel : EN.cancel
            }
        });
        setViewLoader(true)
        const companyId = props.match.params.id;
        const { data } = await callAPI(`/company/${id}/remove/${companyId}`, {
            id: id, companyId: companyId
        }, 'GET');
        if (data.status) {
            $crud.notify({
                type: 'success',
                message: data.message
            })
            let tmp = [...users]
            tmp[mainIndex].names.splice(i, 1)
            setUsers([...tmp])
            // setAllUsers([...tmp])
            // await getCompanyAdmins();
            // await getEmployeesDept(company);
            // getCompanyDetails()
        }
        setViewLoader(false)
    }

    const assignAdminRights = async (id, index, buldingAdmin, mainIndex) => {
        setViewLoader(true)
        if (!buldingAdmin) {
            const { data } = await callAPI(`/building/${props.project_id}/admin/${id}`, null, 'GET');
            if (data.status) {
                $crud.notify({
                    type: 'success',
                    message: data.message
                })
                let tmp = [...admins]
                admins[mainIndex].names[index].buldingAdmin = true
                setAdmins([...tmp])
            }
            else {
                $crud.notify({
                    type: 'error',
                    message: data.message
                })
            }
        }
        else {
            const { data } = await callAPI(`/building/${props.project_id}/admin/${id}`, null, 'DELETE');
            if (data.status) {
                $crud.notify({
                    type: 'success',
                    message: data.message
                })
                let tmp = [...admins]
                admins[mainIndex].names[index].buldingAdmin = false
                setAdmins([...tmp])
            }
            else {
                $crud.notify({
                    type: 'error',
                    message: data.message
                })
            }
        }
        setViewLoader(false)
    }

    const _removeMyself = async () => {
        setViewLoader(true)
        const { data } = await callAPI(`/building/${props.project_id}/admin/${user.id}`, null, 'DELETE');
        if (data.status) {
            $crud.notify({
                type: 'success',
                message: data.message
            })
            setViewLoader(false)
            props.handleEditClose()
        }
        else {
            $crud.notify({
                type: 'success',
                message: data.message
            })
            setViewLoader(false)
            props.handleEditClose()
        }
    }



    return (
        <div class="w-300" style={{ position: "absolute", height: "100%", width: "100%", borderTopWidth: 0 }}>
            {viewLoader && (
                <div className="website_loader">
                    <div className="loader" />
                </div>
            )}
            <div style={{ borderBottom: "1px solid #dfdfdf", paddingBottom: 10 }}>
                <div onClick={(e) => props.handleEditClose()} style={{ padding: 0, margin: 0, display: 'flex', flexDirection: "row" }}>
                    <a
                        style={{
                            color: props.isInternal ? KJUUP_COLORS.internal : "#f08438",
                            padding: 0, margin: 0,
                            // position:"absolute"
                        }}
                    >
                        {!props.isInternal ?
                            <img
                                width={10}
                                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                            />
                            :
                            <img
                                width={10}
                                className="custom-svg"
                                src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                            />
                        }
                    </a>
                    <div
                        style={{

                            fontSize: "18px",
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: 10
                        }}
                    >
                        {props.name}
                    </div>
                </div>
            </div>
            <div className="setting_accrd" style={{ overflow: 'scroll' }}>
                <Accordion defaultActiveKey="0">
                    {props.isAdmin ?
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1"
                                onClick={e => handleClass('1')}>{isGerm ? DE.add_remove_Poss : EN.add_remove_Poss}
                                {
                                    (display === '1') &&
                                    <BsChevronUp className="acr_up" />
                                }
                                {
                                    (display !== '1') &&
                                    <BsChevronDown className="acr_down" />
                                }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <div className="admin_right">
                                        <div className="search_div">
                                            <div className="search">
                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control autocomplete="off" value={search}
                                                        type="search"
                                                        placeholder={isGerm ? DE.search : EN.search}
                                                        onChange={e => filterAdmins(e.target.value)} />
                                                </Form.Group>
                                                <BsSearch />
                                            </div>
                                        </div>
                                        {
                                            admins &&
                                            _.sortBy(admins, [us => us?.lastName?.toLowerCase()], ['lastName']).map((admin, index) => (
                                                <div className="admin-content">
                                                    {admin.names.filter(e => e.requestNotYetAccepted)?.length == admin.names.length ?
                                                        <></>
                                                        :
                                                        < h5 style={{ background: '#f7f7f7' }}>{admin.name}</h5>
                                                    }
                                                    {
                                                        admin.names &&
                                                        admin.names.map((u, i) => (
                                                            u.requestNotYetAccepted ?
                                                                <></>
                                                                :
                                                                <div className="admin-contact" onClick={async () => {
                                                                    await $crud.confirm({
                                                                        title: '',
                                                                        textContent: !u.buldingAdmin ? (isGerm ? DE.add_member_admin_right : EN.add_member_admin_right) : (isGerm ? DE.remove_member_admin_right : EN.remove_member_admin_right),
                                                                        options: {
                                                                            ok: isGerm ? DE.confirm : EN.confirm,
                                                                            cancel: isGerm ? DE.cancel : EN.cancel
                                                                        }
                                                                    });
                                                                    assignAdminRights(u.id, i, u.buldingAdmin, index)
                                                                }}>
                                                                    <div className="row m-0 ">
                                                                        <div className="col-md-10 p-0">
                                                                            <div className="admin-control">
                                                                                <img width={40} height={40}
                                                                                    style={{ borderRadius: u.avatar ? 5 : 0 }}
                                                                                    src={u.avatar ? BASE_URL + u.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                                                                    alt="Generic placeholder" />
                                                                                <h4>{u.fullName}
                                                                                    {/* <span>{convertPosition(isGerm, u.position)}</span> */}
                                                                                    <span>{u.buldingAdmin ? (isGerm ? DE.admin : EN.admin) : (isGerm ? DE.member : EN.member)}</span>
                                                                                    {
                                                                                        (u.department_name && (u.location_name && u.location_name != 'null')) ?
                                                                                            <span>{u.department_name + "/" + u.location_name}</span>
                                                                                            :
                                                                                            <span>{u.department_name}</span>
                                                                                    }
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 pr-0">
                                                                            <div className="custom_checkbox">
                                                                                <label className={company?.isKjuup ? "role-label-internal" : "role-label"}>
                                                                                    <input
                                                                                        // checked={u.addStatus}
                                                                                        checked={
                                                                                            u.buldingAdmin

                                                                                        }
                                                                                        type="checkbox"
                                                                                        value={u.id}
                                                                                        onClick={(e) => {
                                                                                            // addUser(u);
                                                                                        }}
                                                                                    />
                                                                                    <span className={company?.isKjuup ? "checkmark-label-internal" : "checkmark-label"} />
                                                                                </label>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        :
                        <></>
                    }
                    {(totalAdmins > 0 && props.isAdmin) ?
                        <Card>
                            <div style={{ height: '40px', display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center", paddingLeft: 10, paddingRight: 10 }} onClick={async (e) => {
                                await $crud.confirm({
                                    title: '',
                                    textContent: isGerm ? DE.remove_my_self_project : EN.remove_my_self_project,
                                    options: {
                                        ok: isGerm ? DE.confirm : EN.confirm,
                                        cancel: isGerm ? DE.cancel : EN.cancel
                                    }
                                });
                                _removeMyself()
                            }} as={Card.Header}>
                                <div style={{ flex: 1, fontWeight: "550" }}>
                                    {isGerm ? DE.remove_my_admin_right : EN.remove_my_admin_right}
                                </div>
                                <BsX size={'20px'} />
                            </div>
                        </Card>
                        :
                        <></>
                    }
                    <Card>
                        <div style={{}}>
                            {props.isJoin && !props.viewByOwner && (
                                <div onClick={(e) => props.leaveProject(props.project_id)} style={{ height: '40px', display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10 }}>
                                    <span style={{ fontWeight: "540" }}>{isGerm ? DE.leave_project : EN.leave_project}</span>
                                    <div className="svg_icon px-0 mx-0">
                                        <BsX size={'20px'} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card>
                </Accordion>
            </div>
        </div >

    );
}

export default ProjectSettings;
