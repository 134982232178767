import React, { useEffect, useState } from 'react';
import { GROUPS, OPEN_CHATS } from "../../store";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { $user } from "../../helper/UserFactory";
import "./SingleChat.css"
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import {
    AiOutlineFile,
    BsCheck,
    BsCheckAll,
    BsFillReplyFill,
    FiChevronDown,
    IoEllipsisHorizontalSharp
} from "react-icons/all";
import { ReactComponent as GoogleMap } from "../../Icons/GoogleMap.svg";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import { urlify } from '../../helper/convertor';
import PlayerFeed from '../../components/PlayerFeed';

const user = $user.get();

function MessageInfo(props) {
    console.log(props);
    const { isGerm } = React.useContext(LanguageContext);
    const [chat, setChat] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [readByMembers, setReadByMembers] = useState([]);
    const [reachedToMembers, setReachedToMembers] = useState([]);
    const [messageInfo, setMessageInfo] = useState(null);
    const [image, setImage] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [doc, setDoc] = useState('');
    const [viewDoc, setViewDoc] = useState(false);
    const getDetails = async () => {
        const id = props.chatMessage.id;
        const { data } = await callAPI(`/message/${id}/info`, {}, 'GET');
        if (data.status) {
            console.log(data);
            const readyByUsers = data.messageInfo.readByMembers.filter(user => ((user.isRead === true) && (user.isReached === true)))
            setReadByMembers(readyByUsers);
            const reachedToUsers = data.messageInfo.readByMembers.filter(user => ((user.isRead === false) && (user.isReached === true)))
            setReachedToMembers(reachedToUsers);
            console.log(reachedToUsers);
            setLoaded(true);
        }
    };
    useEffect(() => {
        getDetails();
        setChat(props.chatMessage);
    }, []);

    const toggleInfo = (info) => {
        if (!messageInfo) {
            setMessageInfo(info)
        } else if (messageInfo.id === info.id) {
            setMessageInfo(null)
        } else {
            setMessageInfo(info)
        }
    }


    return (
        <React.Fragment>
            <div className="w-300 chat_text m-0 border-radius-0" style={{ height: "93vh", borderColor: props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color, display: "flex", flexDirection: "column" }}>

                <div className="chat_header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="client_name" onClick={props.handleClose} style={{ paddingTop: "4.4px", paddingBottom: "4.4px" }}>
                                <div className="back_number">
                                    <a className="pointer">
                                        {props.isInternal ?
                                            <img width={10} src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                                                alt="Generic placeholder" />
                                            :
                                            <img width={10} src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                                                alt="Generic placeholder" />
                                        }
                                    </a>
                                </div>
                                <div style={{ fontWeight: "600", fontSize: "16px", paddingLeft: 10 }}>
                                    {isGerm ? DE.message_info : EN.message_info}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, overflow:"scroll" }}>
                    <div className="chat_desc pl-3 pr-3" id="chat-sc">
                        <div
                            className={"user_chat_content right_users"}>
                            <div>
                                {chat?.messageType !==
                                    "contact" ? (
                                    <div>
                                        {!chat.isDeleted ? (
                                            <div
                                                className="user_info_chat pb-1"
                                                id={`chat-${chat.id}`}
                                            >

                                                {chat.isReply && (
                                                    <div
                                                        className="reply-right pointer"

                                                    >
                                                        {chat.inReplyTo
                                                            ?.messageType !=
                                                            "image" ?
                                                            <div style={{ flexDirection: "row", display: "flex" }}>
                                                                <p
                                                                    className="mb-0"
                                                                    style={{
                                                                        color: "#4693FD",
                                                                        fontSize: 12
                                                                    }}
                                                                >
                                                                    {chat?.inReplyTo.senderId !== user?.id ? chat?.inReplyTo.senderName : (isGerm ? DE.you : EN.you)}
                                                                </p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {chat.inReplyTo
                                                            ?.messageType ===
                                                            "contact" && (
                                                                <div className="right_msg_reply">
                                                                    <div className="right_reply_img">
                                                                        <img
                                                                            style={{
                                                                                height: 45,
                                                                                width: 45,
                                                                            }}
                                                                            src={
                                                                                chat
                                                                                    .inReplyTo
                                                                                    ?.contacts[0]
                                                                                    ?.avatar
                                                                                    ? BASE_URL +
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        ?.contacts[0]
                                                                                        ?.avatar
                                                                                    : process
                                                                                        .env
                                                                                        .PUBLIC_URL +
                                                                                    "/assets/img/head.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <p
                                                                        className={`m-0 simple-msg-${chat.id}`}
                                                                    >
                                                                        <span className="user-message">
                                                                            {" "}
                                                                            {
                                                                                chat
                                                                                    .inReplyTo
                                                                                    ?.contacts[0]
                                                                                    .fullName
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {chat.inReplyTo
                                                            ?.messageType ===
                                                            "map" && (
                                                                <div className="row d-block">
                                                                    <div className="col-12">
                                                                        <p className="m-0">
                                                                            {chat.inReplyTo.message.includes(
                                                                                "Meeting"
                                                                            )
                                                                                ? "Meeting Point"
                                                                                : "Location"}
                                                                        </p>
                                                                    </div>
                                                                    <div className="right_reply_img col-12">
                                                                        <GoogleMap
                                                                            style={{
                                                                                width: 60,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {chat.inReplyTo
                                                            ?.messageType ===
                                                            "audio" && (
                                                                <div className="pointer">
                                                                    <div className="d-flex align-items-center pt-1">
                                                                        <div className="user-avatar">
                                                                            <img
                                                                                src={
                                                                                    chat
                                                                                        ?.inReplyTo
                                                                                        ?.senderAvatar
                                                                                        ? BASE_URL +
                                                                                        chat
                                                                                            ?.inReplyTo
                                                                                            ?.senderAvatar
                                                                                        : process
                                                                                            .env
                                                                                            .PUBLIC_URL +
                                                                                        "/assets/img/Head-Plain.svg"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="audio-file">
                                                                            <audio
                                                                                controls
                                                                            >
                                                                                <source
                                                                                    src={
                                                                                        chat.local ?
                                                                                            chat
                                                                                                ?.fileUrl[0] :
                                                                                            BASE_URL +
                                                                                            chat
                                                                                                .inReplyTo
                                                                                                ?.fileUrl[0]
                                                                                    }
                                                                                    type="audio/wav"
                                                                                />
                                                                            </audio>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {chat.inReplyTo
                                                            ?.messageType ===
                                                            "video" && (
                                                                <div className="video-message">
                                                                    <video height="294"
                                                                        controls
                                                                        width="294"
                                                                    >
                                                                        <source
                                                                            src={
                                                                                chat.local ?
                                                                                    chat
                                                                                        ?.fileUrl[0] :
                                                                                    BASE_URL +
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        .fileUrl[0]
                                                                            }
                                                                            type="video/webm"
                                                                        ></source>
                                                                    </video>
                                                                </div>
                                                            )}
                                                        {chat?.inReplyTo
                                                            ?.messageType ===
                                                            "simple" && (
                                                                <div>
                                                                    <p
                                                                        className={`m-0 simple-reply-msg-${chat.id}`}
                                                                    >
                                                                        <span
                                                                            className="user-message"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    urlify(
                                                                                        chat
                                                                                            .inReplyTo
                                                                                            .message
                                                                                    ),
                                                                            }}
                                                                        />{" "}
                                                                    </p>
                                                                </div>
                                                            )}

                                                        {chat?.inReplyTo
                                                            ?.fileUrl && (
                                                                <div>
                                                                    <div>
                                                                        {chat
                                                                            ?.inReplyTo
                                                                            ?.messageType ===
                                                                            "image" ? (
                                                                            <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                                                                                <div style={{ width: '22vh', height: "5.5vh" }}>
                                                                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                                                                        <p className="text-primary" style={{ color: "#4693FD", fontSize: 12 }}>{chat?.inReplyTo.senderId !== user?.id ? chat?.inReplyTo.senderName : (isGerm ? DE.you : EN.you)}</p>
                                                                                    </div>
                                                                                    <div style={{ flexDirection: "row", display: "flex", marginTop: -10 }}>
                                                                                        <div>
                                                                                            <img
                                                                                                width={"20px"}
                                                                                                height={"20px"}
                                                                                                src={
                                                                                                    process.env.PUBLIC_URL +
                                                                                                    "/assets/img/add-media.svg"
                                                                                                }
                                                                                                alt="Generic placeholder"
                                                                                                style={{ objectFit: "contain" }}
                                                                                            />
                                                                                        </div>
                                                                                        <p style={{ color: "grey", fontSize: 12, marginTop: 2, marginLeft: 5 }}>{"Photo"}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ flex: 1 }}>
                                                                                    <img
                                                                                        src={
                                                                                            chat.local ?
                                                                                                chat
                                                                                                    ?.fileUrl[0] :
                                                                                                BASE_URL +
                                                                                                chat
                                                                                                    .inReplyTo
                                                                                                    ?.fileUrl[0]
                                                                                        }
                                                                                        style={{ height: "50px", width: "50px" }}
                                                                                    // style={{ objectFit: "contain" }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <p
                                                                        className={`m-0 simple-reply-msg-${chat.id}`}
                                                                    >
                                                                        {chat
                                                                            ?.inReplyTo
                                                                            ?.messageType ===
                                                                            "doc" ? (
                                                                            <span className="user-message">
                                                                                {" "}
                                                                                <AiOutlineFile />{" "}
                                                                                {
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        .attachedFileName
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="user-message"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        urlify(
                                                                                            chat
                                                                                                .inReplyTo
                                                                                                .message
                                                                                        ),
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}

                                                {chat.isForward && (
                                                    <p className="color-gray m-0">
                                                        <BsFillReplyFill
                                                            size={15}
                                                            style={{
                                                                fill: "#cebdbd",
                                                                marginBottom: 3,
                                                            }}
                                                        />{" "}
                                                        Forwarded
                                                    </p>
                                                )}
                                                {chat.all && chat.all.length > 0 &&
                                                    <div style={{ width: "32vh", height: "20vh", marginBottom: 10, marginRight: 10, marginLeft: 10, marginTop: 5 }}>
                                                        <PlayerFeed
                                                            Links={chat.all}
                                                            article={{}}
                                                        />
                                                    </div>
                                                }
                                                {chat?.messageType ===
                                                    "simple" ? (
                                                    <div>
                                                        <p
                                                            className={`m-0 simple-msg-${chat.id}`}
                                                        >
                                                            {chat.isDeleted ? (
                                                                <span>
                                                                    This
                                                                    message
                                                                    has been
                                                                    deleted.
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="user-message"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            urlify(
                                                                                chat.message
                                                                            ),
                                                                    }}
                                                                />
                                                            )}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {chat?.messageType ===
                                                    "image" &&
                                                    !chat.isDeleted ? (
                                                    <div>
                                                        <div
                                                            className="group-ct-image"
                                                            style={{
                                                                height:
                                                                    parseInt(
                                                                        chat.width
                                                                    ) >
                                                                        parseInt(
                                                                            chat.height
                                                                        )
                                                                        ? 165
                                                                        : parseInt(
                                                                            chat.width
                                                                        ) ===
                                                                            parseInt(
                                                                                chat.height
                                                                            )
                                                                            ? 294
                                                                            : 294,
                                                                width: 294,
                                                            }}
                                                        >
                                                            <img
                                                                onClick={() => {
                                                                    setImage(
                                                                        chat.local ?
                                                                            chat
                                                                                ?.fileUrl[0] :
                                                                            BASE_URL +
                                                                            chat
                                                                                ?.fileUrl[0]
                                                                    );
                                                                    setShowImage(
                                                                        true
                                                                    );
                                                                }}
                                                                className="w-100"
                                                                src={
                                                                    chat.isReply
                                                                        ? BASE_URL +
                                                                        chat.fileUrl
                                                                        :
                                                                        chat.local ?
                                                                            chat
                                                                                ?.fileUrl[0] :
                                                                            BASE_URL +
                                                                            chat
                                                                                .fileUrl[0]
                                                                }
                                                            />
                                                        </div>
                                                        <p
                                                            className={`m-0 p-1 simple-msg-${chat.id}`}
                                                        >
                                                            <span
                                                                className="user-message"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        urlify(
                                                                            chat.message
                                                                        ),
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {chat?.messageType ===
                                                    "doc" &&
                                                    !chat.isDeleted ? (
                                                    <div
                                                        className="pointer text-left"
                                                        onClick={() => {
                                                            setDoc(chat);
                                                            setViewDoc(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <AiOutlineFile />{" "}
                                                        {
                                                            chat.attachedFileName
                                                        }
                                                        <p className="m-0 p-1">
                                                            <span
                                                                className="user-message"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        urlify(
                                                                            chat.message
                                                                        ),
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {chat?.messageType ===
                                                    "audio" &&
                                                    !chat.isDeleted ? (
                                                    <div className="pointer">
                                                        <div className="d-flex align-items-center pt-1">
                                                            <div className="user-avatar">
                                                                <img
                                                                    src={
                                                                        chat.senderAvatar
                                                                            ? BASE_URL +
                                                                            chat.senderAvatar
                                                                            : process
                                                                                .env
                                                                                .PUBLIC_URL +
                                                                            "/assets/img/Head-Plain.svg"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="audio-file">
                                                                <audio
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={
                                                                            chat.local ?
                                                                                chat
                                                                                    ?.fileUrl[0] :
                                                                                BASE_URL +
                                                                                chat
                                                                                    ?.fileUrl[0]
                                                                        }
                                                                        type="audio/wav"
                                                                    />
                                                                </audio>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {chat?.messageType ===
                                                    "video" &&
                                                    !chat.isDeleted ? (
                                                    <div className="video-message">
                                                        <video height="294"
                                                            controls
                                                            width="294"
                                                        >
                                                            {chat.isReply ? (
                                                                <source
                                                                    src={
                                                                        BASE_URL +
                                                                        chat.fileUrl
                                                                    }
                                                                    type="video/webm"
                                                                />
                                                            ) : (
                                                                <source
                                                                    src={
                                                                        chat.local ?
                                                                            chat
                                                                                ?.fileUrl[0] :
                                                                            BASE_URL +
                                                                            chat
                                                                                .fileUrl[0]
                                                                    }
                                                                    type="video/webm"
                                                                />
                                                            )}
                                                        </video>
                                                        <p
                                                            className={`m-0 p-1 simple-msg-${chat.id}`}
                                                        >
                                                            <span
                                                                className="user-message"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        urlify(
                                                                            chat.message
                                                                        ),
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {chat?.messageType ===
                                                    "map" &&
                                                    !chat.isDeleted ? (
                                                    <div
                                                        className="map"
                                                    // id={`map-${index}`}
                                                    >
                                                        <p className="m-0 p-1">
                                                            <span className="user-message">
                                                                {chat.message.includes(
                                                                    "Meeting"
                                                                )
                                                                    ? "Meeting Point"
                                                                    : "Location"}
                                                            </span>
                                                        </p>
                                                        <div className="">
                                                            <a
                                                                href={
                                                                    chat.message
                                                                }
                                                                target="_blank"
                                                            >
                                                                <GoogleMap
                                                                    style={{
                                                                        width: 60,
                                                                    }}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {!chat.isDeleted && (
                                                    <div className="small">
                                                        {moment(
                                                            chat.createdAt
                                                        ).format(
                                                            "HH:mm"
                                                        )}
                                                        {chat.messageReadByAll ? (
                                                            <BsCheckAll
                                                                size={15}
                                                                className="theme-color"
                                                            />
                                                        ) : chat.messageReachedToAll ? (
                                                            <BsCheckAll
                                                                size={15}
                                                                color="#737373"
                                                            />
                                                        ) : (
                                                            <BsCheck
                                                                size={15}
                                                                color="#737373"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="user_info_chat pb-1">
                                                <p className="m-0">
                                                    <span>
                                                        {isGerm
                                                            ? DE.message_delete
                                                            : EN.message_delete}
                                                    </span>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {!chat.isDeleted ? (
                                            <div className="contact-section">
                                                <div className="contact mb-2">
                                                    {chat.contacts &&
                                                        chat.contacts.map(
                                                            (c, i) => (
                                                                <div
                                                                    className="mb-2 contact-right  p-2"
                                                                    key={i}
                                                                    id={`chat-${chat.id}`}
                                                                >

                                                                    {chat.isForward && (
                                                                        <p className="color-gray m-0">
                                                                            <BsFillReplyFill
                                                                                size={
                                                                                    15
                                                                                }
                                                                                style={{
                                                                                    fill: "#cebdbd",
                                                                                    marginBottom: 3,
                                                                                }}
                                                                            />{" "}
                                                                            Forwarded
                                                                        </p>
                                                                    )}
                                                                    <Link
                                                                        to={`/user-profile/${c.id}`}
                                                                    >
                                                                        <div>
                                                                            <span className="contact-name">
                                                                                {
                                                                                    c.fullName
                                                                                }
                                                                            </span>
                                                                            <img
                                                                                className="contact-image"
                                                                                style={{
                                                                                    borderRadius: 6,
                                                                                }}
                                                                                src={
                                                                                    c.avatar
                                                                                        ? BASE_URL +
                                                                                        c.avatar
                                                                                        : process
                                                                                            .env
                                                                                            .PUBLIC_URL +
                                                                                        "/assets/img/head.svg"
                                                                                }
                                                                                alt="Generic placeholder"
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                    <div className="clearfix" />
                                                                    {!chat.isDeleted && (
                                                                        <div className="small">
                                                                            {moment(
                                                                                chat.createdAt
                                                                            ).format(
                                                                                "HH:mm"
                                                                            )}
                                                                            {chat.messageReadByAll ? (
                                                                                <BsCheckAll
                                                                                    size={15}
                                                                                    className="theme-color"
                                                                                />
                                                                            ) : chat.messageReachedToAll ? (
                                                                                <BsCheckAll
                                                                                    size={15}
                                                                                    color="#737373"
                                                                                />
                                                                            ) : (
                                                                                <BsCheck
                                                                                    size={15}
                                                                                    color="#737373"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="user_info_chat pb-1">
                                                <p className="m-0">
                                                    <span>
                                                        {isGerm
                                                            ? DE.message_delete
                                                            : EN.message_delete}
                                                    </span>
                                                </p>
                                                <div className="small">
                                                    {moment(
                                                        chat.createdAt
                                                    ).format("HH:mm")}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={readByMembers.length > 0 ? "message_ready_by row m-0 p-2" : "message_ready_by row m-0 p-0"}>
                        {
                            readByMembers?.length > 0 &&
                            <div className="col-12" style={{ borderBottom: '1px solid #eee', paddingBottom: 10 }}>
                                <BsCheckAll size={15} color={KJUUP_COLORS.base_color} /> {isGerm ? DE.read_by : EN.read_by}
                            </div>

                        }
                        <div className="col-12">
                            {
                                readByMembers &&
                                readByMembers.map((member, index) => (
                                    <div className="row align-items-center read_by_member pointer" key={index}>
                                        <div className="col-2 p-2 text-center">
                                            <img style={{ borderRadius: "50%", height: 34, width: 34 }}
                                                src={member.avatar ? BASE_URL + member.avatar : process.env.PUBLIC_URL + "/assets/img/user-circle.png"} />
                                        </div>
                                        <div onClick={() => toggleInfo(member)}
                                            className={(messageInfo && messageInfo.id === member.id) ? 'col-10 p-2 text-black text-center' : 'col-6 p-2 text-black'}>{member.fullName}</div>

                                        {
                                            !(messageInfo && messageInfo.id === member.id) &&
                                            <div className="col-4 p-2 small text-center">
                                                {member?.readDateTime ? moment(member.readDateTime).format('DD.MM.Y HH:mm') : '---'}
                                            </div>
                                        }
                                        {
                                            (messageInfo && messageInfo.id === member.id) &&
                                            <div className="col-12">
                                                <div className="row read_by_member-time">
                                                    <div className="col-2 text-center p-1">
                                                        <BsCheckAll size={15} className="theme-color" />
                                                    </div>
                                                    <div className="col-5 p-1">{isGerm ? DE.read_by : EN.read_by}</div>
                                                    <div
                                                        className="col-5 p-1 text-right small">
                                                        {member?.readDateTime ? moment(member.readDateTime).format('DD.MM.YY HH:mm') : '---'}
                                                    </div>
                                                </div>
                                                <div className="row read_by_member-time">
                                                    <div className="col-2 text-center p-1">
                                                        <BsCheckAll size={15} color="#737373" />
                                                    </div>
                                                    <div className="col-5 p-1">{isGerm ? DE.deliver_to : EN.deliver_to}</div>
                                                    <div
                                                        className="col-5 p-1 text-right small">
                                                        {member?.reachDateTime ? moment(member.reachDateTime).format('DD.MM.YY HH:mm') : '---'}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>

                        {
                            reachedToMembers.length > 0 &&
                            <div className="col-12 text-center"
                                style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
                                <IoEllipsisHorizontalSharp />
                            </div>
                        }
                        {
                            reachedToMembers.length > 0 &&
                            <div className="col-12" style={{ borderBottom: '1px solid #eee', paddingTop: 10, paddingBottom: 10 }}>
                                <BsCheckAll size={15} color="#737373" /> {isGerm ? DE.deliver_to : EN.deliver_to}
                            </div>
                        }
                        {
                            reachedToMembers.length > 0 &&
                            <div className="col-12">

                                {
                                    reachedToMembers &&
                                    reachedToMembers.map((member, index) => (
                                        <div className="row align-items-center" key={index}>
                                            <div className="col-2 p-2 text-center">
                                                <img style={{ borderRadius: "50%", height: 34, width: 34 }}
                                                    src={member.avatar ? BASE_URL + member.avatar : process.env.PUBLIC_URL + "/assets/img/user-circle.png"} />
                                            </div>
                                            <div
                                                className="col-6 p-2 text-black">{member.fullName}</div>
                                            <div className="col-4 p-2 small text-center">
                                                {member?.reachDateTime ? moment(member.reachDateTime).format('DD.MM.Y HH:mm') : '---'}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { openChats, groups } = state;
    return {
        groups: groups,
        openChats: openChats,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateGroups: groups => dispatch({ type: GROUPS, groups: groups }),
        updateOpenChatsList: chats => dispatch({ type: OPEN_CHATS, openChats: chats }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageInfo));
