import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { EN } from "../../Languages/EN";
import { DE } from "../../Languages/DE";
import LanguageContext from "../../helper/LanguageContext";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { $crud } from "../../helper/CrudFactory";
import { BASE_URL, KJUUP_COLORS, TOGGLE_DRAWER, goBack } from "../../helper/constants";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { BsChevronLeft, BsFillEyeFill, BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import ShareMapDialog from "../../components/ShareMapDialog";
import moment from "moment";
import CompanyContactDialog from "./Dialogs/CompanyContactDialog";
import Carousel from "react-multi-carousel";
import { CompanyArticle } from "../Feeds/CompanyArticle";
import { Form, Card } from "react-bootstrap";
import { ReactComponent as ShareLink } from "../../Icons/Share-link.svg";
import {
  ARTICLE,
  GROUPS,
  GROUPS_BOTTOM,
  OPEN_BOTTOM_CHATS,
  OPEN_CHATS,
  PROJECTS,
  RIGHT_TOP,
  store,
  TOGGLE_WORK_AREA,
  UPDATE_PROJECT_ACTION_FIRE,
} from "../../store";
import { connect } from "react-redux";
import FeedViewDialog from "../Feeds/Dialogs/FeedViewDialog";
import { ReactComponent as ArrowRight } from "../../Icons/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../Icons/ArrowLeft.svg";
import { Animated } from "react-animated-css";
import UserProjectList from "./UserProjectList";
import { $user } from "../../helper/UserFactory";
import {
  convertBranchRole,
  convertPosition,
  convertRole,
  formatAddress,
} from "../../helper/convertor";
import { DateFormatter } from "../../helper/ConstantsArrays";
import IGRENN from '../../Icons/InternalGreen.png';
import INormal from '../../Icons/Internal-Project.png';
import ExternalOrange from '../../Icons/ExternalOrange.png';
import ExternalNormal from '../../Icons/External-Project.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CreateArticleChoose from "../Feeds/CreateArticleChoose";
import RCPlayer from "../../components/RCPlayer";
import { ReactComponent as JobportalDeactived } from '../../Icons/Job portal-Deactivated.svg';



function a11yProps(index, value) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: {
      // backgroundColor: (index == 0 && value == index) ? "yellow" : (index == 1 && value == index) ? "blue" : "white",
      color: (index == 0 && value == index) ? "#288f6c" : (index == 1 && value == index) ? "#f08438" : "grey",
      borderWidth: 0,
      padding: 0,
      textTransform: "none",
      fontSize: "1.8vh",
      bottom: 7
      // fontWeight: "200"
    }
  };
}


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    // renderTrackHorizontal={()=>{
    //   return <></>
    // }}
    {...props}
  />
);

function CompanyDetails(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const location = useLocation();
  const history = useHistory();
  const CarouselEl = useRef();
  const CarouselElInternal = useRef();
  const CarouselElExternal = useRef();
  const [search, setSearch] = useState("");
  const [slideNo, setSlideNo] = useState(0);
  const [slideNoDep, setSlideNoDep] = useState(0);
  const [slideNoExternal, setSlideNoExternal] = useState(1);
  const [slideNoInternal, setSlideNoInternal] = useState(1);
  const [modalData, setModalData] = useState([]);
  const [showPlayer, setShowPlayer] = useState(false)
  const [initialIndex, setInitalIndex] = useState(0)
  const [company, setCompany] = useState({});
  const [capacities, setCapacities] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersBackUp, setUsersBackUp] = useState([]);
  const [showEmployee, setShowEmployee] = useState(false);
  const [showCapacity, setShowCapacity] = useState(false);
  const [show, setShow] = useState(false);
  const [showContactDialog, setContactDialog] = useState(false);
  const [services, setServices] = useState([]);
  const [ExternalArticles, setExternalArticles] = useState([]);
  const [InternalArticles, setInternalArticles] = useState([]);
  const [chooseArticle, setChooseArticle] = useState(false);
  const [departments, setDepartments] = useState([])
  const [getDeps, setgetDeps] = useState(false)
  const [fromContact, setFromContact] = useState(false)
  const [viewProjectList, setProjectList] = useState({
    userId: null,
    status: false,
    companyId: null,
    name: "",
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (props.history.location) {
      if (props.history.location.state) {
        setFromContact(props.history.location.state.fromContact)
      }
    }
  }, [props])
  const getCompanyDetails = async () => {
    const id = props.match.params?.id;
    const { data } = await callAPI(`/company/${id}/profile`, {}, "GET");
    console.log('----->Company Details', data)
    await setCompany(data.profile);
    if (data.profile.articleCount > 0) {
      setSlideNo(1);
    }
    await setServices(data.profile.services);
    setgetDeps(true)
  };

  useEffect(() => {
    if (getDeps) {
      _getDepartmentAndLocations()
      setgetDeps(false)
    }
  }, [getDeps])

  const getInternalArticles = async (depid) => {
    const id = props.match.params?.id;
    const { data } = await callAPI(`/company/${id}/getCompanyArticle`, depid ? {
      articleType: "1",
      deptLocation: depid
    } : { articleType: "1" }, "GET");
    setInternalArticles(data.articles)
  }

  const getExternalArticles = async (depid) => {
    const id = props.match.params?.id;
    const { data } = await callAPI(`/company/${id}/getCompanyArticle`, {
      articleType: "0"
    }, "GET");
    console.log('----external articles', data.articles, depid)
    setExternalArticles(data.articles)
  };

  const getCapacities = async () => {
    const id = props.match.params?.id;
    const { data } = await callAPI(
      `/company/${id}/getCompanyCapacities`,
      {},
      "GET"
    );
    console.log(data);
    await setCapacities(data.capacities);
  };

  useEffect(() => {
    getCompanyDetails();
    getCapacities();
  }, [props.match.params?.id]);

  const _getDepartmentAndLocations = async () => {
    const id = props.match.params?.id;
    const { data } = await callAPI(`/company/getDeptLocation/${id}`, {}, "GET");
    if (data.status) {
      setDepartments(data.data.getCompanyDept)
      if (data.data.getCompanyDept.length > 0) {
        if (company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" || company.position === "EMPLOYEE") {
          getInternalArticles(company.deptLocationId)
          getExternalArticles(company.deptLocationId)
          getEmployees(0, data.data.getCompanyDept)
        }
        else {
          if (company.position || company.role) {
            getInternalArticles(data.data.getCompanyDept[0]?.id)
            getExternalArticles(data.data.getCompanyDept[0]?.id)
            getEmployees(0, data.data.getCompanyDept)
          }
        }
      }
      else {
        getInternalArticles()
        getExternalArticles()
        getEmployeesWithotDep()
      }
    }
    else {
      getInternalArticles()
      getExternalArticles()
      getEmployeesWithotDep()
    }
  }

  const getEmployeesWithotDep = async (dno, deps) => {
    const id = props.match.params?.id;
    const { data } = await callAPI(`/company/${id}/request`, {}, "GET");
    await CreateList(data.members);
  };
  const leaveCompany = async () => {
    await $crud.confirm({
      title: isGerm ? DE.requet_to_leave : EN.requet_to_leave,
      textContent: isGerm ? DE.leaving_company_text : EN.leaving_company_text,
      options: {
        ok: isGerm ? DE.ok : EN.ok,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(
      `/company/${location.state?.id}/leave`,
      {},
      "GET"
    );
    if (data.status) {
      props.updateProjects([]);
      store.dispatch({
        type: UPDATE_PROJECT_ACTION_FIRE,
        updateProjectActionFire: true,
      });
    }
    history.push("/profile");
  };
  const getEmployees = async (dno, deps) => {
    console.log('------cpmsl,sd', company, deps)
    const id = props.match.params?.id;
    let urll = (departments.length > 0) ? departments[dno]?.id : deps ? deps[dno]?.id : ''
    const { data } = (company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" || company.position === 'EMPLOYEE') ? await callAPI(`/company/${id}/request?deptLocation=${company.deptLocationId}`, {}, "GET") : await callAPI(`/company/${id}/request?deptLocation=${urll}`, {}, "GET");
    await CreateList(data.members);
  };
  const CreateList = async (users) => {
    const arr = _.uniqBy(
      users?.map((user) => {
        let name = { name: user.lastName.trim()[0].toUpperCase() };
        return name;
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    const usersList = updatedArr?.map((a) => {
      let names = users.filter((u) => u.lastName.trim()[0].toUpperCase() === a.name);
      a.names = names;
      return a;
    });
    setUsers(usersList);
    setUsersBackUp(usersList);
  };
  const followCompany = async (id) => {
    const { data } = await callAPI(
      `/company/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );
    console.log(data);
    getCompanyDetails();
  };

  const deleteService = async (id) => {
    await $crud.confirm({
      title: isGerm ? DE.confirm : EN.confirm,
      textContent: isGerm ? "Sind Sie sicher, dass Sie die Unternehmensleistung löschen möchten?" : "Are you sure you want to delete the company service?",
      options: {
        ok: isGerm ? DE.ok : EN.ok,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(
      `/service/${id}/delete`,
      {
        companyId: company?.id,
        id: id,
      },
      "DELETE"
    );
    const newList = await services.filter((service) => service?.id !== id);
    setServices(newList);
  };

  const approveService = async (value) => {
    const { data } = await callAPI(
      `/service/${value}/join`,
      {
        id: value,
      },
      "GET"
    );
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    getCompanyDetails();
  };

  const expandArticle = async (key) => {
    console.log(key);
    const articles = company?.articles;
    let article = articles[key];
    article.expand = true;
    articles[key] = article;
    company.articles = articles;
    await setCompany(company);
  };
  const handleDeleteArticle = async (article) => {
    await $crud.confirm({
      title: isGerm ? DE.confirm : EN.confirm,
      textContent: isGerm ? 'Sind Sie sicher, dass Sie diesen Artikel löschen wollen?' : "Are you sure you want to delete this article?",
      options: {
        ok: isGerm ? DE.ok : EN.ok,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(`/article/${article?.id}`, {}, "DELETE");
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    let articles = company.articles;
    articles = articles.filter((a) => a?.id !== article?.id);
    setCompany((prev) => {
      return {
        ...prev,
        articles: articles,
      };
    });
  };

  const CustomRightArrow = (val) => {
    console.log(val, slideNoInternal)
    if (val == 1) {
      CarouselElInternal.current.next();
      let snp = slideNoInternal
      snp = snp + 1
      if (snp) {
        setSlideNoInternal(snp);
      }
    }
    else if (val == 0) {
      CarouselElExternal.current.next();
      let snp = slideNoExternal
      snp = snp + 1
      if (snp) {
        setSlideNoExternal(snp);
      }
    }
    else {
      CarouselEl.current.next();
      let snp = slideNo
      snp = snp + 1
      if (snp) {
        setSlideNo(snp);
      }
    }
  };

  const CustomLeftArrow = (val) => {
    if (val == 1) {
      CarouselElInternal.current.previous();
      let snp = slideNoInternal
      snp = snp - 1
      if (snp !== -1) {
        setSlideNoInternal(snp);
      }
    }
    else if (val == 0) {
      CarouselElExternal.current.previous();
      let snp = slideNoExternal
      snp = snp - 1
      if (snp !== -1) {
        setSlideNoExternal(snp);
      }
    }
    else {
      CarouselEl.current.previous();
      let snp = slideNo
      snp = snp - 1
      if (snp !== -1) {
        setSlideNo(snp);
      }
    }

  };
  const [visible, setVisible] = useState(true);
  const handleBack = () => {
    setVisible(false);
    goBack();

    // if (history.location.state) {
    //   history.push({ pathname: "/articles", state: history.location.state })
    // } else {
    //   setTimeout(() => {
    //     goBack();
    //   }, 1000);
    // }
  };

  const addProjectUser = async (u) => {
    const user = $user.get();
    const { data } = await callAPI(`/group`, {}, "POST", {
      groupType: "user",
      isOneToOne: true,
      users: [user?.id, u?.id],
    });
    console.log(data);
    if (data.chatExists) {
      const group = await props.groups.find((cht) => cht?.id === data.chat?.id);
      console.log(group);
      if (group) {
        props.updateToggleWorkArea(true);
        props.updateRightTop("chat1");
        setTimeout(async () => {
          const chk = await props.openChats.find((g) => g?.id === data.chat?.id);
          console.log(chk);
          if (chk) {
            await props.updateOpenChats(
              _.uniqBy([...props.openChats, chk], "id")
            );
          } else {
            await props.updateOpenChats([...props.openChats, group]);
            const updateGroup = await props.groups?.map((g) => {
              if (g?.id === group?.id) {
                return { ...g, isOpened: true };
              } else {
                return { ...g };
              }
            });
            props.updateGroups(updateGroup);
            props.updateGroupsBottom(updateGroup);
          }
        }, 800);
      }
    }
  };

  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const _renderExternalArts = () => {
    return (
      ExternalArticles.length > 0 ?
        <div>
          <div className="article padding-left-right-2 bg-normal">
            <div className="slider-icon">
              <a href="#" onClick={(e) => {
                if (slideNoExternal > 1)
                  CustomLeftArrow(0)
              }}>
                <ArrowLeft
                  className={
                    slideNoExternal > 1 ? "svg_active" : "svg_gray"
                  }
                  width={9}
                  height={17}
                />
              </a>
              <p>
                {slideNoExternal} {isGerm ? DE.of : EN.of}{" "}
                {ExternalArticles?.length}{" "}
                {isGerm ? DE.articlen : EN.articlen}
              </p>
              <a href="#" onClick={(e) => {
                if (slideNoExternal < ExternalArticles.length)
                  CustomRightArrow(0)
              }}>
                <ArrowRight
                  className={
                    ExternalArticles?.length > slideNoExternal
                      ? "svg_active"
                      : "svg_gray"
                  }
                  width={9}
                  height={17}
                />
              </a>
            </div>
          </div>
          <Carousel
            ref={CarouselElExternal}
            additionalTransfrom={0}
            arrows={false}
            centerMode={false}
            className="p-0"
            containerClass="container"
            dotListClass=""
            draggable={company?.articles?.length > 1}
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
              },
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {ExternalArticles &&
              ExternalArticles?.map((article, key) => (
                <div key={key} className="media-desc">
                  {
                    <CompanyArticle
                      viewArticleDetail={props.viewArticle}
                      company={company}
                      reloadArticle={() => getCompanyDetails()}
                      index={key}
                      article={article}
                      expandArticle={expandArticle}
                      removeArticle={handleDeleteArticle}
                      disableButtons={true}
                      onPress={(data, index) => {
                        setModalData(data)
                        setInitalIndex(index)
                        setShowPlayer(true)
                      }}
                    />
                  }
                </div>
              ))}
          </Carousel>

        </div>
        :
        <></>
    )
  }

  const _renderInternalArts = () => {
    return (
      InternalArticles.length > 0 ?
        <div>
          <div className="article padding-left-right-2 bg-normal">
            <div className="slider-icon">
              <a href="#" onClick={(e) => {
                if (slideNoInternal > 1)
                  CustomLeftArrow(1)
              }}>
                <ArrowLeft
                  className={
                    slideNoInternal > 1 ? company.isKjuup ? 'svg_active_internal' : "svg_active" : "svg_gray"
                  }
                  width={9}
                  height={17}
                />
              </a>
              <p>
                {slideNoInternal} {isGerm ? DE.of : EN.of}{" "}
                {InternalArticles?.length}{" "}
                {isGerm ? DE.articlen : EN.articlen}
              </p>
              <a href="#" onClick={(e) => {
                if (slideNoInternal < InternalArticles.length)
                  CustomRightArrow(1)
              }}>
                <ArrowRight
                  className={
                    InternalArticles?.length > slideNoInternal
                      ? company.isKjuup ? 'svg_active_internal' : "svg_active"
                      : "svg_gray"
                  }
                  width={9}
                  height={17}
                />
              </a>
            </div>
          </div>

          <Carousel
            ref={CarouselElInternal}
            additionalTransfrom={0}
            arrows={false}
            centerMode={false}
            className="p-0"
            dotListClass=""
            draggable={company?.articles?.length > 1}
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
              },
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {InternalArticles &&
              InternalArticles?.map((article, key) => (
                <div key={key} className="media-desc">
                  {
                    <CompanyArticle
                      viewArticleDetail={props.viewArticle}
                      company={company}
                      reloadArticle={() => getCompanyDetails()}
                      index={key}
                      article={article}
                      expandArticle={expandArticle}
                      removeArticle={handleDeleteArticle}
                      isInternal={true}
                      disableButtons={true}
                      onPress={(data, index) => {
                        setModalData(data)
                        setInitalIndex(index)
                        setShowPlayer(true)
                      }}
                    />
                  }
                </div>
              ))}
          </Carousel>

        </div>
        :
        <></>
    )
  }


  const filterAdmins = async (searchValue) => {
    setSearch(searchValue);
    searchValue = searchValue.trim();
    if (searchValue) {
      let userListFilter = await usersBackUp.map((item) => {
        let filterName = item.names.filter((itm) => {
          let fullName =
            `${itm.firstName.trim()} ${itm.lastName.trim()}`.toLowerCase();
          if (
            itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            fullName.toLowerCase().startsWith(searchValue.toLowerCase())
          ) {
            return true;
          }
        });
        return { ...item, names: filterName };
      });
      userListFilter = userListFilter.filter(
        (position) => position.names.length > 0
      );
      setUsers(userListFilter);
    } else {
      setUsers(usersBackUp);
    }
  };


  return (
    <React.Fragment>
      <Layout>
        {!viewProjectList.status && (
          <Animated
            animationIn="slideInLeft"
            animationOut="slideOutLeft"
            animationOutDuration={800}
            isVisible={visible}
          >
            {company && (
              <div className="company_section w-300 pb-0 p-0" style={{ height: "93vh", display: "flex", flexDirection: "column", }}>
                <div className="" style={{ flex: 1, overflow: "scroll", overflowX: "hidden" }}>
                  {/* <CustomScrollbars
                    autoHide
                    autoHideTimeout={500}
                    autoHideDuration={200}
                    className="bottom-bar"
                  > */}
                  <div className="bg-purple">
                    <div className="row">
                      <div className="col-md-5 pr-0">
                        <div className="user_profile">
                          <div className="user_img">
                            <a className="pointer" onClick={handleBack}>
                              {company.isKjuup ?
                                <img
                                  className="img-fluid"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/backx.svg"
                                  }
                                  alt="Generic placeholder"
                                />
                                :
                                <img
                                  className="img-fluid"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/arrow_left-selected.svg"
                                  }
                                  alt="Generic placeholder"
                                />
                              }
                            </a>
                            <img
                              className="company_img"
                              width={55}
                              height={55}
                              src={
                                company.avatar
                                  ? BASE_URL + company.avatar
                                  : process.env.PUBLIC_URL +
                                  "/assets/img/Companies-No-Image.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 pl-0">
                        <div className={company.isKjuup ? 'follow-internal' : "follow"}>
                          <div className={company.isKjuup ? "follow_item_internal text-center" : "follow_item text-center"}>
                            <h3>{company?.followerCount}</h3>
                            <Link to={`/company-followers/${company?.id}`}>
                              <p>{isGerm ? DE.followers : EN.followers}</p>
                            </Link>
                          </div>
                          <div className="follow_item text-center" >
                            <h3 style={{ color: company.isKjuup ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}>{company?.articleCount}</h3>
                            <Link>
                              <p>{isGerm ? DE.article : EN.article}</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <a
                          onClick={(e) => {
                            followCompany(company?.id);
                          }}
                          // className={
                          //   company.followStatus
                          //     ? !company.isKjuup ? `svg_icon pointer active-like-follow` : `svg_icon pointer active-like-follow-internal`
                          //     : "svg_icon pointer"
                          // }
                          className={`svg_icon pointer ${company.followStatus ? (company.isKjuup ? 'social-icon-active_internal' : 'social-icon-active') : ''}`}
                        >
                          <svg className="mr-1" width="25px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 19.81 20.9">
                            <path
                              d="M295.14,269.16a1.28,1.28,0,0,0-1.32-1.23h-.91a5.11,5.11,0,0,1-8.76,0h-.91a1.27,1.27,0,0,0-1.31,1.23l-1.31,9a1.28,1.28,0,0,0,1.31,1.24h13.21a1.27,1.27,0,0,0,1.3-1.24Z"
                              transform="translate(-280.17 -258.91)" />
                            <path className="cls-1"
                              d="M288.53,268.2a4.42,4.42,0,1,0-4.41-4.42A4.43,4.43,0,0,0,288.53,268.2Z"
                              transform="translate(-280.17 -258.91)" />
                            <line className="cls-2" x1="16.72" y1="0.75" x2="16.72"
                              y2="3.47" />
                            <line className="cls-2" x1="16.72" y1="3.47" x2="16.72"
                              y2="6.18" />
                            <line className="cls-2" x1="16.72" y1="3.47" x2="14.08"
                              y2="3.47" />
                            <line className="cls-2" x1="19.36" y1="3.47" x2="16.72"
                              y2="3.47" />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="profile-flied">
                      <div className="profile_name">
                        <h3>{company?.companyName}</h3>
                        {/* {company.owner ? (
                        <p>
                          {isGerm
                            ? DE.owner_company_admin_new
                            : EN.owner_company_admin}
                          <span className="theme-color"> {company.owner}</span>
                        </p>
                      ) : (
                        ""
                      )} */}
                        <p>
                          {company.full_address ? company.full_address : formatAddress(company)}
                        </p>
                        <p>{convertBranchRole(company.branch, isGerm)}</p>
                        <p>
                          {isGerm ? DE.my_position : EN.my_position}{" "}
                          {convertPosition(isGerm, company.position)}
                        </p>
                      </div>
                    </div>
                    <div className="profile-flied">
                      <div className="icon_right icon-left">
                        <a href="#" onClick={handleShow}>
                          <img
                            width={16}
                            src={
                              process.env.PUBLIC_URL + "/assets/img/Location.svg"
                            }
                          />
                        </a>
                        {company.role === "ROLE_COMPANY_SUPER_ADMIN" ? (
                          <>
                            {company.role === "ROLE_COMPANY_SUPER_ADMIN" &&
                              <Link to={`/edit-company/${company?.id}`}>
                                <img
                                  width={22}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/edit-pen-s.svg"
                                  }
                                />
                              </Link>
                            }
                            <Link to={`/free-capacity`}>
                              <JobportalDeactived height={'22px'} width={'22px'} />

                            </Link>
                            {/* <Link to={`/free-capacity`}>
                              <img
                                width={24}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/capacities-deselected.svg"
                                }
                              />
                            </Link> */}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {(company.role === "ROLE_COMPANY_SUPER_ADMIN" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN") ? (
                        <>
                          <div className="icon_right">
                            <Link
                              to={{
                                pathname: `/company-setting/${company?.id}/${company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" ? company.deptLocationId : departments[slideNoDep]?.id}`,
                                state: { isKjuup: company.isKjuup }
                              }}>
                              <img
                                width={23}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/settings.svg"
                                }
                              />
                            </Link>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {
                    (company.position == "EMPLOYEE" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN") && company?.department ?
                      <div className={departments.length > 0 || company?.department || company.isKjuup ? "bg-green mt-10" : "bg-purple mt-10"}>
                        <h3
                          className="middle-title pointer"
                          onClick={(e) => {
                            // setShowCapacity(!showCapacity);
                          }}
                          style={{ textAlign: "center" }}
                        >

                          {isGerm
                            ? DE.dep_loc
                            : EN.dep_loc}
                        </h3>
                        <div className="slider-icon px-2 middle-title">
                          <a href="#" onClick={(e) => {
                            if (slideNoDep) {
                              // setSlideNoDep(slideNoDep - 1)
                            }
                          }}>
                            {/* <ArrowLeft
                                className={
                                  slideNoDep >= 1 ? company.isKjuup?'svg_active_internal': "svg_active" : "svg_gray"
                                }
                                width={9}
                                height={17}
                              /> */}
                          </a>
                          {/* <p> */}
                          {company.location ? `${company.department}/${company.location}` : `${company.department}`}
                          {/* </p> */}
                          <a href="#" onClick={(e) => {

                            // setSlideNoDep(slideNoDep + 1)
                          }}>
                            {/* <ArrowRight
                                className={
                                  departments.length - 1 > slideNoDep
                                    ? company.isKjuup?'svg_active_internal': "svg_active"
                                    : "svg_gray"
                                }
                                width={9}
                                height={17}
                              /> */}
                          </a>
                        </div>

                      </div>
                      :
                      <></>
                  }
                  {departments.length > 0 && company.position == "COMPANY ADMIN" ?
                    <div className={departments.length > 0 || company?.department || company.isKjuup ? "bg-green mt-10" : "bg-purple mt-10"}>
                      <h3
                        className="middle-title pointer"
                        onClick={(e) => {
                          // setShowCapacity(!showCapacity);
                        }}
                        style={{ textAlign: "center" }}
                      >

                        {isGerm
                          ? DE.dep_loc
                          : EN.dep_loc}
                      </h3>
                      <div className="slider-icon px-0 middle-title " >
                        <a href="#" onClick={(e) => {
                          if (slideNoDep) {
                            getEmployees(slideNoDep - 1)
                            getInternalArticles(departments[slideNoDep - 1]?.id)
                            getExternalArticles(departments[slideNoDep - 1]?.id)
                            setSlideNoDep(slideNoDep - 1)
                            setInternalArticles([])
                            setExternalArticles([])
                            setSlideNoExternal(1)
                            setSlideNoInternal(1)
                          }
                        }}>
                          <ArrowLeft
                            className={
                              slideNoDep >= 1 ? company.isKjuup ? 'svg_active_internal' : "svg_active" : "svg_gray"
                            }
                            width={9}
                            height={17}
                          />
                        </a>
                        {/* <p className="middle-title pointer">  */}
                        {(departments[slideNoDep].location_name && departments[slideNoDep].location_name != 'null') ? `${departments[slideNoDep].department_name}/${departments[slideNoDep].location_name}` : `${departments[slideNoDep].department_name}`}
                        {/* </p> */}
                        <a href="#" onClick={(e) => {
                          if (slideNoDep < departments.length - 1) {
                            getEmployees(slideNoDep + 1)
                            getInternalArticles(departments[slideNoDep + 1]?.id)
                            getExternalArticles(departments[slideNoDep + 1]?.id)
                            setSlideNoDep(slideNoDep + 1)
                            setInternalArticles([])
                            setExternalArticles([])
                            setSlideNoExternal(1)
                            setSlideNoInternal(1)
                          }
                        }}>
                          <ArrowRight
                            className={
                              departments.length - 1 > slideNoDep
                                ? company.isKjuup ? 'svg_active_internal' : "svg_active"
                                : "svg_gray"
                            }
                            width={9}
                            height={17}
                          />
                        </a>
                      </div>

                    </div>
                    :
                    <></>
                  }
                  {users.length > 0 ?
                    <div className={departments.length > 0 || company?.department || company.isKjuup ? "bg-green mt-10" : "bg-purple mt-10"}>
                      <h3
                        className="middle-title pointer"
                        onClick={(e) => {
                          setShowEmployee(!showEmployee);
                        }}
                      >
                        <span>
                          {" "}
                          <img
                            width={16}
                            height={22}
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/Employee-deselected.svg"
                            }
                          />
                        </span>
                        {isGerm ? DE.employees : EN.employees}
                      </h3>
                    </div>
                    :
                    <></>}
                  {showEmployee && (
                    <div className="admin-content m-0">
                      {usersBackUp?.length > 0 ?
                        <div className="search_div" style={{ marginTop: 10, marginLeft: 5, marginBottom: 10, border: '1px solid #dfdfdf', borderRadius: 5, overflow: "hidden" }}>
                          <div className="search">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Control
                                autocomplete="off"
                                value={search}
                                type="search"
                                placeholder={isGerm ? DE.search : EN.search}
                                onChange={(e) => filterAdmins(e.target.value)}
                              />
                            </Form.Group>
                            <BsSearch />
                          </div>
                        </div>
                        :
                        <></>
                      }
                      {users &&
                        users?.map((user, index) => (
                          <div key={index}>
                            <h5
                              className="p-2"
                              style={{ background: "#f7f7f7" }}
                            >
                              {user.name}
                            </h5>
                            {user.names &&
                              _.sortBy(
                                user.names,
                                [(us) => us?.lastName?.toLowerCase()],
                                ["lastName"]
                              )?.map((u, i) => (
                                <div key={i} className="">
                                  <div className="row m-0">
                                    <div className="col-md-10 p-2">
                                      <div className="admin-control">
                                        <img
                                          width={40}
                                          height={40}
                                          src={
                                            u.avatar
                                              ? BASE_URL + u.avatar
                                              : process.env.PUBLIC_URL +
                                              "/assets/img/head.svg"
                                          }
                                          alt="Generic placeholder"
                                          style={{ borderRadius: 5 }}
                                        />
                                        <h4 onClick={() => history.push(`/user-profile/${u?.id}`)} className="pointer">
                                          {u.fullName}
                                          <span>{convertPosition(isGerm, u.degination)}</span>
                                          {
                                            (u.department_name && (u.location_name && u.location_name != 'null')) ?
                                              <span>{u.department_name + "/" + u.location_name}</span>
                                              :
                                              <span>{u.department_name}</span>
                                          }
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="col-md-2 p-2">
                                      {(company.role ===
                                        "ROLE_COMPANY_SUPER_ADMIN") && company.position == "COMPANY ADMIN" && (
                                          <BsFillEyeFill
                                            size={18}
                                            color="grey"
                                            className="pointer"
                                            onClick={() => {
                                              setProjectList({
                                                status: true,
                                                userId: u?.id,
                                                companyId:
                                                  props.match.params?.id,
                                                name: u.fullName,
                                              });
                                              setShowEmployee(false);
                                            }}
                                          />
                                        )}
                                      {(company.role ===
                                        "ROLE_COMPANY_SUPER_ADMIN" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN") && (
                                          // <img
                                          //   style={{ marginLeft: 10 }}
                                          //   onClick={() => addProjectUser(u)} height="22"
                                          //   src={process.env.PUBLIC_URL + "/assets/img/create-article-gray.svg"}
                                          //   alt="Generic placeholder" />
                                          <BsFillPlusCircleFill onClick={() => addProjectUser(u)} size={20} color={'gray'} />

                                        )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  )}
                  {company.role === "ROLE_COMPANY_SUPER_ADMIN" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" ||
                    company.role === "ROLE_COMPANY_ADMIN" ? (
                    <div className={departments.length > 0 || company?.department || company.isKjuup ? "bg-green mt-10" : "bg-purple mt-10"}>
                      {/* {console.log(company.position == "COMPANY ADMIN" ? departments.length > 0 || company?.department ? `/add-user/${company?.id}/${departments[slideNoDep].department_id}` : `/add-user/${company?.id}` : `/add-user/${company?.id}/${company.deptLocationId}`)} */}
                      <Link
                        to={{
                          pathname: company.position == "COMPANY ADMIN" ?
                            (departments.length > 0 || company?.department) ? `/add-user/${company?.id}/${departments[slideNoDep]?.id}` : `/add-user/${company?.id}/${null}`
                            : `/add-user/${company?.id}/${company.deptLocationId}`,
                          state: { isKjuup: company.isKjuup }
                        }}
                        className="middle-title"
                      >
                        <span>
                          {" "}
                          <img
                            width={16}
                            height={22}
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/add_employee.svg"
                            }
                          />
                        </span>
                        {isGerm ? DE.add_employee : EN.add_employee}
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {
                    (company.isKjuup && !fromContact) ?

                      <div className={departments.length > 0 || company?.department || company.isKjuup ? "bg-green mt-10" : "bg-purple mt-10"} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ fontWeight: "500" }}>
                          {isGerm ? DE.article : EN.article}
                        </div>
                        <div onClick={() => setChooseArticle(true)}>
                          {company.role === "ROLE_COMPANY_SUPER_ADMIN" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" ? (
                            <BsFillPlusCircleFill size={20} color={!company.isKjuup ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      :
                      <></>
                  }
                  <div className="main_heading" style={{ marginBottom: 0, }}>
                    {
                      (company.isKjuup && !fromContact) ?
                        <Tabs
                          variant="fullWidth"
                          value={value}
                          onChange={handleChange}
                          TabIndicatorProps={{ style: { background: value == 0 ? "#288f6c" : "#f08438" } }}
                          style={{ width: "100%", backgroundColor: "white", height: 55 }}
                        >
                          <Tab icon={value == 1 ?
                            <img
                              id={'myimg12'}
                              src={INormal}
                              style={{ height: 25, width: 25, }}
                            />
                            :
                            <img
                              id={'myimg13'}
                              src={IGRENN}
                              style={{ height: 25, width: 25, }}
                            />
                          } iconPosition="start" label={isGerm ? DE.internal_project : EN.internal_project} {...a11yProps(0, value)} />
                          <Tab icon={
                            value == 0 ?
                              <img
                                id={'myimg2'}
                                src={ExternalNormal}
                                style={{ height: 25, width: 25, }}
                              />
                              :
                              <img
                                id={'myimg9'}
                                src={ExternalOrange}
                                style={{ height: 25, width: 25, }}
                              />
                          } iconPosition="start" label={isGerm ? DE.external_projects : EN.external_projects} {...a11yProps(1, value)} />
                        </Tabs>
                        :
                        <></>}
                  </div>
                  <div className="profile-data">
                    {(!company.isKjuup && !fromContact) ?
                      <div>
                        <div className="article padding-left-right-2">
                          <div className="profile-flied">
                            <div className="profile_name">
                              <h3>{isGerm ? DE.article : EN.article}</h3>
                            </div>
                            <div className="icon_right">
                              {company.role === "ROLE_COMPANY_SUPER_ADMIN" ? (
                                company.isKjuup ?
                                  <Link to={`/add-company-article/${company?.id}`}>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/img/article-edit.png"
                                      }
                                    />
                                  </Link>
                                  :
                                  <div onClick={() => {
                                    $crud.notify({
                                      type: "error",
                                      message: isGerm ? DE.only_kjuup_plus_can : EN.only_kjuup_plus_can
                                    })
                                  }}>
                                    {/* <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/img/create-article.svg"
                                      }
                                    /> */}
                                    <BsFillPlusCircleFill size={20} color={!company.isKjuup ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal} />
                                  </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="slider-icon">
                            <a href="#" onClick={(e) => {
                              if (slideNo > 1)
                                CustomLeftArrow()
                            }}>
                              <ArrowLeft
                                className={
                                  slideNo > 1 ? company.isKjuup ? 'svg_active_internal' : "svg_active" : "svg_gray"
                                }
                                width={9}
                                height={17}
                              />
                            </a>
                            <p>
                              {slideNo} {isGerm ? DE.of : EN.of}{" "}
                              {company?.articleCount}{" "}
                              {isGerm ? DE.articlen : EN.articlen}
                            </p>
                            <a href="#" onClick={(e) => {
                              if (slideNo < company.articles.length)
                                CustomRightArrow()
                            }}>
                              <ArrowRight
                                className={
                                  company.articleCount > slideNo
                                    ? company.isKjuup ? 'svg_active_internal' : "svg_active"
                                    : "svg_gray"
                                }
                                width={9}
                                height={17}
                              />
                            </a>
                          </div>
                        </div>
                        {company.articles && company.articles?.length > 0 ? (
                          <Carousel
                            ref={CarouselEl}
                            additionalTransfrom={0}
                            arrows={false}
                            centerMode={false}
                            className="p-0"
                            containerClass="container"
                            dotListClass=""
                            draggable={company?.articles?.length > 1}
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                              desktop: {
                                breakpoint: {
                                  max: 3000,
                                  min: 1024,
                                },
                                items: 1,
                              },
                              mobile: {
                                breakpoint: {
                                  max: 464,
                                  min: 0,
                                },
                                items: 1,
                              },
                              tablet: {
                                breakpoint: {
                                  max: 1024,
                                  min: 464,
                                },
                                items: 1,
                              },
                            }}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                          >
                            {company.articles &&
                              company.articles?.map((article, key) => (
                                <div key={key} className="media-desc">
                                  {
                                    <CompanyArticle
                                      viewArticleDetail={props.viewArticle}
                                      company={company}
                                      reloadArticle={() => getCompanyDetails()}
                                      index={key}
                                      article={article}
                                      expandArticle={expandArticle}
                                      removeArticle={handleDeleteArticle}
                                      disableButtons={true}
                                      onPress={(data, index) => {
                                        setModalData(data)
                                        setInitalIndex(index)
                                        setShowPlayer(true)
                                      }}
                                    />
                                  }
                                </div>
                              ))}
                          </Carousel>
                        ) : (
                          ""
                        )}
                      </div>
                      :
                      fromContact ?
                        _renderExternalArts()
                        :
                        value == 0 ?
                          _renderInternalArts()
                          :
                          _renderExternalArts()
                    }
                    <div className="bg-purple mt-10">
                      <h3
                        className="middle-title pointer"
                        onClick={(e) => {
                          setShowCapacity(!showCapacity);
                        }}
                      // style={{ fontSize: '15px', marginTop: 20, }}
                      >
                        <span>
                          {" "}
                          <img
                            width={22}
                            height={22}
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/Capacities-deselected-gray.svg"
                            }
                          />
                        </span>
                        {isGerm
                          ? DE.reported_capacities
                          : EN.reported_capacities}
                      </h3>
                    </div>
                    {showCapacity && (
                      <div className="admin-content bg-company m-0">
                        <div className="row m-0">
                          {capacities &&
                            capacities?.map((capacity) => (
                              <div key={capacity?.id} className="col-12 p-2">
                                <div style={{ fontSize: 13 }}>
                                  <strong>{isGerm ? DE.by : EN.by} {capacity.reportedBy} | </strong>{" "}
                                  <span className="font_color">
                                    {convertPosition(isGerm, capacity.designation)}
                                  </span>
                                </div>
                                <div
                                  className="font_color"
                                  style={{ fontSize: 13 }}
                                >
                                  {capacity.services}
                                </div>
                                <div
                                  className="font_color"
                                  style={{ fontSize: 13 }}
                                >
                                  {capacity.city} + {capacity.circuit}
                                </div>
                                <div
                                  className="font_color"
                                  style={{ fontSize: 13 }}
                                >
                                  {DateFormatter(capacity.from, isGerm)}{" "}
                                  -{" "}
                                  {DateFormatter(capacity.to, isGerm)}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    <div className="company_services company_download px-3 bg-normal">
                      <h5>
                        {isGerm
                          ? DE.company_details_service
                          : EN.company_details_service}
                        {company.role === "ROLE_COMPANY_SUPER_ADMIN" ||
                          company.role === "ROLE_COMPANY_ADMIN" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" ? (
                          <Link
                            to={{
                              pathname: `/add-service/${company?.id}`,
                              state: { isKjuup: company.isKjuup }
                            }}
                          >
                            <img
                              className="im`g-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/edit-pen-s.svg"
                              }
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </h5>
                      {/* <small>
                          {isGerm
                            ? DE.invite_user_to_confirm_service
                            : EN.invite_user_to_confirm_service}
                        </small>
                        <div
                          className="upload"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Link to={`/invite-user/${company?.id}`}>
                            <ShareLink style={{ width: 15 }} />
                          </Link>
                          <span
                            style={{
                              color: "#737373",
                              marginLeft: 5,
                              marginTop: 5,
                            }}
                          >
                            {isGerm
                              ? DE.share_company_link
                              : EN.share_company_link}
                          </span>
                        </div> */}
                      <div className="row">
                        <div className="offset-7 col-5 text-right">
                          <small className="font-weight-bold">
                            {isGerm ? DE.approve : EN.approve}
                          </small>
                        </div>
                      </div>
                      {services &&
                        services?.map((service) => (
                          <div key={service?.id} className="archi">
                            <div className="row align-items-center">
                              <div className="col-md-11">
                                <div className="services_delete">
                                  <a
                                    key={service?.id}
                                    style={{ cursor: "pointer" }}
                                  // to={`/service-members/${service?.id}`}
                                  >

                                    <span className="small" onClick={() => history.push(
                                      {
                                        pathname: `/service-members/${service?.id}`,
                                        state: { isKjuup: company.isKjuup }
                                      }
                                    )}>
                                      {convertRole(service.service, isGerm)} |{" "}
                                      {service.usersCount}
                                    </span>
                                    {company.role ===
                                      "ROLE_COMPANY_SUPER_ADMIN" ||
                                      company.role === "ROLE_COMPANY_ADMIN" || company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" ? (
                                      <img
                                        onClick={(e) =>
                                          deleteService(service?.id)
                                        }
                                        width={14}
                                        height={17}
                                        className="img-fluid"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/img/trashcan.svg"
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </a>
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="radio_btn">
                                  <label className={company.isKjuup ? 'role-label-internal pt-0' : "role-label pt-0"}>
                                    <input
                                      value={service?.id}
                                      disabled={service.isMember}
                                      checked={service.isMember}
                                      onChange={(e) => {
                                        approveService(e.target.value);
                                      }}
                                      type="checkbox"
                                    />
                                    <span className={company.isKjuup ? "checkmark-label-internal" : "checkmark-label"} />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="company_services company_download px-3 bg-normal">
                      {company.position == "COMPANY ADMIN" ?
                        <h5>
                          {isGerm ? DE.company_contact : EN.company_contact}
                          {company.role === "ROLE_COMPANY_SUPER_ADMIN" ||
                            company.role === "ROLE_COMPANY_ADMIN" ? (
                            <img
                              className="img-fluid pointer"
                              onClick={(e) => setContactDialog(true)}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/edit-pen-s.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </h5>
                        :
                        <></>
                      }
                      <div className="company_centact">
                        <div className="website">
                          <div className="website_img">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/Website-grey.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </div>
                          <div className="details-company" style={{ cursor: "pointer" }}>
                            <h5>{isGerm ? DE.website : EN.website}</h5>
                            <a href={'https://' + company?.website} target="_blank" style={{ fontSize: "13px", color: company.isKjuup ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}>{company?.website}</a>
                          </div>
                        </div>
                        <div className="website">
                          <div className="website_img">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/e-mail-grey.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </div>
                          <div className="details-company">
                            <div>
                              <h5>{isGerm ? DE.email : EN.email}</h5>
                              <p onClick={() => {
                                navigator.clipboard.writeText(company?.email)
                                  .then(() => {
                                    $crud.notify({
                                      type: "success",
                                      message: isGerm ? DE.email_copied : EN.email_copied,
                                    });
                                  })
                                  .catch(() => {

                                  });
                              }} style={{ cursor: "pointer", color: company.isKjuup ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}>{company?.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`company_services ${company.isKjuup ? 'leave_company-internal' : 'leave_company'} px-3 bg-normal`}>
                      <h5 onClick={(e) => leaveCompany()}>
                        {isGerm ? DE.leave_the_company : EN.leave_the_company}
                      </h5>
                    </div>
                  </div>
                  {/* </CustomScrollbars> */}
                </div>
                <ShareMapDialog
                  company={company}
                  show={show}
                  handleClose={handleClose}
                />
              </div>
            )}
            {!company && (
              <div className="company_section w-300">
                <div className="bg-purple">
                  <div className="row">
                    <div className="col-md-5 pr-0">
                      <div className="user_profile">
                        <div className="user_img">
                          <Link to="/profile">
                            <img
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/arrow_left-selected.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </Link>
                          <img
                            width={55}
                            height={55}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/Companies-No-Image.svg"
                            }
                            alt="Generic placeholder"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2 pt-3 text-center">No Record Found!</div>
              </div>
            )}
            {showContactDialog && (
              <CompanyContactDialog
                handleClose={(e) => {
                  setContactDialog(false);
                  getCompanyDetails();
                }}
                company={company}
              />
            )}
            {props.article && <FeedViewDialog article={props.article} />}
          </Animated>
        )}

        {viewProjectList.status && (
          <UserProjectList
            details={viewProjectList}
            isKjuup={company.isKjuup}
            hide={() =>
              setProjectList({
                status: false,
                userId: null,
                companyId: null,
                name: "",
              })
            }
          />
        )}
        {
          chooseArticle &&
          <CreateArticleChoose
            isKjuup={company.isKjuup}
            close={() => setChooseArticle(false)}
            companyId={company?.id}
            deptLocationId={company.role === "ROLE_COMPANY_DEPARTMENT_ADMIN" ? company.deptLocationId : departments[slideNoDep]?.id}
          />
        }
        {showPlayer ?
          <RCPlayer on Links={modalData} initialIndex={initialIndex} closeModal={() => {
            setModalData([])
            setInitalIndex(0)
            setShowPlayer(false)
          }} />
          :
          <></>
        }
      </Layout>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    article,
    projects,
    groups,
    groupsBottom,
    openChats,
    openBottomChats,
  } = state;
  return {
    article: article,
    projects: projects,
    groups: groups,
    groupsBottom: groupsBottom,
    openChats: openChats,
    openBottomChats: openBottomChats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProjects: (projects) =>
      dispatch({ type: PROJECTS, projects: projects }),
    viewArticle: (article) => dispatch({ type: ARTICLE, article: article }),
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateGroupsBottom: (groups) =>
      dispatch({ type: GROUPS_BOTTOM, groupsBottom: groups }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
    updateToggleWorkArea: (value) =>
      dispatch({ type: TOGGLE_WORK_AREA, toggleWorkArea: value }),
    updateRightTop: (value) => dispatch({ type: RIGHT_TOP, rightTop: value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyDetails));
