import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { Link, withRouter } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import ShareProjectMapDialog from "../../components/ProjectMapShare/ShareProjectMapDialog";
import CompanyListDialog from "./Dialogs/CompanyListDialog";
import { $crud } from "../../helper/CrudFactory";
import _ from "lodash";
import {
  PROJECTS,
} from "../../store";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { goBack } from "../../helper/constants";

function Project(props) {
  const [params, setParams] = useState({
    companyId: "",
    id: "",
    companySelect: false,
  });
  const { isGerm } = React.useContext(LanguageContext);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [allProjects, setAllProject] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [showCompanyDialog, setCompanyDialog] = useState(false);
  const [companies, setCompanies] = useState([]);
  const getCompanies = async () => {
    const { data } = await callAPI(`/company/my`, {}, "GET");
    if (data.status) {
      setCompanies(data.companies);
    }
  };
  const setParam = async (name, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleShow = async (project) => {
    setShow(true);
    setProjectDetails(project);
  };
  const handleClose = () => setShow(false);

  const [projects, setProjects] = useState([]);

  const getProjects = async () => {
    const { data } = await callAPI(
      "/building/home",
      {
        page: page,
        limit: limit,
        query: search,
      },
      "GET"
    );
    console.log(data);
    if (data.status) {
      const results = _.uniqBy([...projects, ...data.buildings], "id");
      setProjects(results);
      setAllProject(results);
    }
  };

  useEffect(() => {
    getProjects();
  }, [page]);

  useEffect(() => {
    getProjects();
    getCompanies();
  }, []);

  const getProjectName = (name) => {
    if (name) {
      const nameArray = name?.split(" ");
      if (nameArray && nameArray.length) {
        const length = nameArray.length;
        let newName = [];
        newName.push(nameArray[0][0]);
        if (nameArray.length > 1) {
          newName.push(nameArray[length - 1][0]);
        }
        return newName.join("");
      }
    }
  };
  const handleJoin = async (project) => {
    console.log(project);
    if (companies.length > 1) {
      await setParam("id", project.id);
      setCompanyDialog(true);
    } else if (companies.length === 1) {
      console.log(project.id);
      console.log(companies[0].id);
      await submitReJoinSingleCompany(project.id, companies[0].id);
    } else {
      await submitReJoinWithoutCompany(project.id);
    }
  };

  const submitReJoinSingleCompany = async (project_id, companyId) => {
    const { data } = await callAPI(
      `/building/${params.id}/join`,
      {
        id: project_id,
        companyId: companyId,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      setPage(1);
      getProjectsList();
      getProjectRecords();
    }
  };

  const submitReJoinCompany = async (company) => {
    const { data } = await callAPI(
      `/building/${params.id}/join`,
      {
        id: params.id,
        companyId: company.id,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      setPage(1);
      getProjectsList();
      getProjectRecords();
    }
  };
  const submitReJoinWithoutCompany = async (id) => {
    console.log(params);
    const { data } = await callAPI(`/building/${id}/join`, {}, "PUT");
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      setPage(1);
      getProjectsList();
      getProjectRecords();
    }
  };
  const getProjectsList = async () => {
    const { data } = await callAPI("/building/my", {}, "GET");
    if (data.status) {
      let sortData = await _.sortBy(data.buildings, [
        (comp) => {
          return comp.updatedAt && !comp.isJoin;
        },
      ]);
      sortData = await sortData.map((building, i) => {
        return { ...building, key: i + 1 };
      });
      props.updateProjects(sortData);
    }
  };
  const getProjectRecords = async () => {
    const { data } = await callAPI(
      "/building/home",
      {
        page: 1,
        limit: limit,
        query: search,
      },
      "GET"
    );
    console.log(data);
    if (data.status) {
      setProjects(data.buildings);
      setAllProject(data.buildings);
    }
  };

  const searchProject = async (e) => {
    const value = e.target.value.trim();
    setSearch(value);
    const searchResult = await projects.filter((project) => {
      if (value === "") {
        return project;
      } else if (project.name.toLowerCase().includes(value.toLowerCase())) {
        return project;
      }
    });
    if (value === "") {
      setProjects(allProjects);
    } else {
      setProjects(searchResult);
    }
  };
  const myRef = useRef();

  const handleScroll = () => {
    const element = myRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      const newPage = page + 1;
      setPage(newPage);
      getProjects();
    }
  };
  const [visible, setVisible] = useState(true);
  const handleBack = () => {
    setVisible(false);
    setTimeout(() => {
      goBack();
    }, 1000);
  };
  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="w-300 my_project pt-0 ">
            <div class="heading_icon">
              <div className="main_heading">
                <img
                  className="pointer"
                  width={10}
                  onClick={handleBack}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
                {isGerm ? DE.project_list : EN.project_list}
              </div>
              <div className="icons_heading">
                <Link to="/add-project">
                  <img
                    width={28}
                    src={
                      process.env.PUBLIC_URL + "/assets/img/Add-Project-PP.svg"
                    }
                  />
                </Link>
              </div>
            </div>
            <div className="search">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control
                  autocomplete="off"
                  type="search"
                  onChange={(e) => searchProject(e)}
                  placeholder={isGerm ? DE.search : EN.search}
                />
              </Form.Group>
              <img
                src={process.env.PUBLIC_URL + "assets/img/search.svg"}
                alt="Generic placeholder"
              />
            </div>
            <div className="project_listing">
              <div className="wscroll" ref={myRef} onScroll={handleScroll}>
                {projects &&
                  projects.map((project) => (
                    <div className="project_info">
                      <div className="Project_name">
                        <div
                          className="project_icon"
                          style={{
                            backgroundColor: !project.isHidden
                              ? project.themeColor
                              : "#d9deda",
                          }}
                        >
                          {getProjectName(project.name)}
                        </div>
                        <div
                          className="p_name"
                          style={{
                            color: !project.isHidden ? "#737373" : "#d9deda",
                          }}
                        >
                          {project.name}
                          <span>{project.city}</span>
                        </div>
                      </div>
                      <div className="project_action">
                        {project.isHidden ? (
                          <Link to={`/add-project-user/${project.id}`}>
                            <img
                              width={27}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/add-to-project.svg"
                              }
                            />
                          </Link>
                        ) : (
                          <a href="#" onClick={(e) => handleJoin(project)}>
                            <img
                              width={22}
                              src={
                                process.env.PUBLIC_URL + "/assets/img/Add.svg"
                              }
                            />
                          </a>
                        )}
                        <a href="#" onClick={(e) => handleShow(project)}>
                          <img
                            width={16}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/Location.svg"
                            }
                          />
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <ShareProjectMapDialog
              company={projectDetails}
              show={show}
              handleClose={handleClose}
            />
            {showCompanyDialog && (
              <CompanyListDialog
                handleClose={(e) => setCompanyDialog(false)}
                setParam={setParam}
                companies={companies}
                handleReJoinCompany={submitReJoinCompany}
              />
            )}
          </div>
        </Animated>
      </Layout>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProjects: (projects) =>
      dispatch({ type: PROJECTS, projects: projects }),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(Project));
