import React, { useCallback, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import MyProject from "../pages/Project/MyProject";
import { connect, useSelector } from "react-redux";
import ProjectDetailSection from "../pages/Project/ProjectChildComponent/ProjectDetail";
import { withRouter } from "react-router-dom";
import {
  RIGHT_BOTTOM,
  RIGHT_TOP,
  TOGGLE_WORK_AREA,
  OPEN_CHATS,
  OPEN_BOTTOM_CHATS,
  OPEN_PROJECTS,
  OPEN_BOTTOM_PROJECTS,
  GROUPS,
  PROJECTS,
  ALL_BADGE_COUNT,
  GROUP_CHATS,
} from "../store";
import Chat from "../pages/Chat/chat";
import Conversation from "../pages/Chat/Coversation";
import { pure } from "recompose";
import { Badge } from "react-bootstrap";
import { callAPI } from "../helper/apiUtils";
import { KJUUP_COLORS } from "../helper/constants";
import { ReactComponent as RoomIcon } from '../Icons/Rooms-activated.svg';
import { ReactComponent as RoomIconFilled } from '../Icons/Rooms-activated-orange.svg';
import { ReactComponent as RoomIconFilledGreen } from '../Icons/Rooms-activated-green.svg';
import { ReactComponent as ChatActived } from '../Icons/One-to-chat-activated-deactived.svg';
import { ReactComponent as ChatDeactived } from '../Icons/One-to-chat-activated.svg';






function RightBar(props) {
  const windowWidth = window.innerWidth - 960;
  const { drawerOpen } = useSelector((state) => state);
  const { unSeenAllCount } = useSelector((state) => state);
  const [bottomAreaWidth, setBottomAreaWidth] = useState(windowWidth);
  const [projectsViewArea, setProjectsViewArea] = useState(windowWidth);
  const [colorC, setColorC] = useState(KJUUP_COLORS.base_color)
  const _serColor = useCallback((e) => setColorC(e), [])
  const inActivity = useSelector(e => e.inActivity)

  useEffect(() => {
    const cntProject = props?.openProjects?.length;
    if (cntProject) {
      let newWidth = drawerOpen ? cntProject * 400 + windowWidth : "100%";
      setProjectsViewArea(newWidth);
    }
  }, [props.openProjects, drawerOpen]);

  useEffect(() => {
    const cntProject = props.openChats.length;
    if (cntProject) {
      let newWidth = drawerOpen ? cntProject * 400 + windowWidth : "100%";
      setProjectsViewArea(newWidth);
    }
  }, [props.openChats, drawerOpen]);

  useEffect(() => {
    const cntProject = props.openBottomChats.length;
    if (cntProject) {
      let newWidth = drawerOpen ? cntProject * 400 + windowWidth : "100%";
      setBottomAreaWidth(newWidth);
    }
  }, [props.openBottomChats, drawerOpen]);

  useEffect(() => {
    const projects = props.openBottomProjects;
    const cntProject = projects.length;
    if (cntProject) {
      let newWidth = drawerOpen ? cntProject * 400 + windowWidth : "100%";
      setBottomAreaWidth(newWidth);
    }
  }, [props.openBottomProjects, drawerOpen]);

  useEffect(() => {
    if (document.querySelector(".project-items")) {
      const slider = document.querySelector(".project-items");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active-chat");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active-chat");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active-chat");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 1; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [inActivity]);

  const { userLoginToken } = useSelector((state) => state);

  //Count API
  const getBadgesCount = async () => {
    if (userLoginToken) {
      const { data } = await callAPI(`/badge/count`, {}, "GET");
      if (data?.status) {
        await props.updateAllBadgeCount(data);
      }
    }
  };
  useEffect(() => {
    getBadgesCount();
  }, []);

  const toggleWorkArea = () => {
    props.updateOpenChats([]);
    props.updateOpenBottomChats([]);
    props.updateOpenProjectsList([]);
    props.updateBottomOpenProjects([]);
    props.updateToggleWorkArea(!props.workArea);
    const updateGroups = props.groups.map((g) => {
      g.isOpened = false;
      return g;
    });
    props.updateGroups(updateGroups);
    const updateProjects = props.projects.map((g) => {
      g.isOpened = false;
      return g;
    });
    props.updateProjects(updateProjects);
  };
  return (
    <React.Fragment>
      <div
        className={`right_side_bar`}
        style={{
          marginLeft: !drawerOpen ? 10 : 0,
          pointerEvents: "all",
          transition: "margin-left 800ms",
        }}
      >
        {
          !inActivity ?
            <>
              <nav
                className="main_nav middle_bar"
                style={
                  props.isOpenByOwner
                    ? { pointerEvents: "none", opacity: "0.5" }
                    : {}
                }
              >
                <ul className="side_nav">
                  <li data-tip data-for="workareatooltip" onClick={()=>{}}>
                    <span className="svg_icon home_svg">
                      <svg
                        width="33px"
                        id="Layer_3"
                        data-name="Layer 3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100.86 100.86"
                      >
                        <rect
                          x="57.75"
                          y="16.74"
                          width="26.37"
                          height="26.37"
                          rx="4.69"
                          ry="4.69"
                        />
                        <rect
                          className="cls-1"
                          x="16.74"
                          y="16.74"
                          width="26.37"
                          height="26.37"
                          rx="4.69"
                          ry="4.69"
                        />
                        <rect
                          className="cls-1"
                          x="16.74"
                          y="57.75"
                          width="26.37"
                          height="26.37"
                          rx="4.69"
                          ry="4.69"
                        />
                        <rect
                          className="cls-1"
                          x="1.5"
                          y="1.5"
                          width="97.86"
                          height="97.86"
                          rx="9.8"
                          ry="9.8"
                        />
                      </svg>
                    </span>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      props.updateRightTop("project1");
                    }}
                    className={
                      (props.rightTop === "project1" && props.workArea)
                        ? colorC == "#288f6c" ? "active_nav-internal bdr_top" : "active_nav bdr_top"
                        : "diactive_nav bdr_top"
                    }
                    data-tip
                    data-for="projecttooltip"
                    style={{ borderColor: props.rightTop !== "project1" ? KJUUP_COLORS.base_color : colorC }}
                  >
                    <span className="svg_icon home_svg">
                      {(props.rightTop === "project1" && props.workArea) && colorC !== '#288f6c' ?
                        <RoomIconFilled
                          fill="#288f6c"
                          stroke="#288f6c"
                          color="#288f6c"
                          height={35} width={35} />
                        :
                        (props.rightTop === "project1" && props.workArea) && colorC == '#288f6c' ?
                          <RoomIconFilledGreen
                            fill={KJUUP_COLORS.base_color}
                            stroke={KJUUP_COLORS.base_color}
                            color={KJUUP_COLORS.base_color}
                            height={35} width={35} />
                          :
                          <RoomIcon
                            fill={KJUUP_COLORS.base_color}
                            stroke={KJUUP_COLORS.base_color}
                            color={KJUUP_COLORS.base_color}
                            height={35} width={35} />
                      }
                      {unSeenAllCount?.unseenBuildingChatCount > 0 && (
                        <Badge pill>{unSeenAllCount?.unseenBuildingChatCount}</Badge>
                      )}
                    </span>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      props.updateRightTop("chat1");
                    }}
                    className={
                      props.rightTop === "chat1" && props.workArea
                        ? "active_nav"
                        : "diactive_nav"
                    }
                    data-tip
                    data-for="chartooltip"
                  >
                    <span className="svg_icon home_svg">
                      {props.rightTop != "chat1" && props.workArea ?
                        <ChatActived
                          fill={KJUUP_COLORS.base_color}
                          stroke={KJUUP_COLORS.base_color}
                          color={KJUUP_COLORS.base_color}
                          height={35} width={35} />
                        :
                        <ChatDeactived
                          // fill={KJUUP_COLORS.base_color}
                          // stroke={KJUUP_COLORS.base_color}
                          // color={KJUUP_COLORS.base_color}
                          height={35} width={35} />
                      }
                      {/* <svg
                        width="38px"
                        id="Layer_6"
                        data-name="Layer 6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100.27 91.21"
                      >
                        <path
                          className="cls-1"
                          d="M241.25,83.79v26.54H232.2a7,7,0,0,1-7.1-6.69V66c0-3.54,3.17-6.31,7.1-6.31h59.44c3.92,0,6.87,2.77,6.87,6.31v9.11H251.79C245.81,75.09,241.25,78.87,241.25,83.79Z"
                          transform="translate(-223.6 -58.17)"
                        />
                        <path
                          className="cls-1"
                          d="M322.37,126.09a7,7,0,0,1-7.09,6.63H311l5.73,12.77-20.38-12.77H255.83a6.75,6.75,0,0,1-6.87-6.63V88.43a6.54,6.54,0,0,1,6.87-6.37h59.45c3.91,0,7.09,2.83,7.09,6.37Z"
                          transform="translate(-223.6 -58.17)"
                        />
                      </svg> */}
                      {unSeenAllCount?.unseenChatCount > 0 && (
                        <Badge pill>{unSeenAllCount?.unseenChatCount}</Badge>
                      )}
                    </span>
                  </li>
                  <li
                    className={
                      props.rightTop === "chat1" && props.workArea
                        ? "active_nav"
                        : "diactive_nav"
                    }
                    data-tip
                    data-for="chartooltip"
                  >
                  </li>
                </ul>
              </nav>
              <div className="right_content">
                <div className="conversation_top">
                  <div className="fix-items">
                    {props.rightTop === "project1" ? <MyProject changeBorder={_serColor} /> : ""}
                    {props.rightTop === "chat1" ? <Chat /> : ""}
                  </div>
                  <div
                    className="hscroll project-items"
                    style={{
                      width: "100%",
                      paddingRight: drawerOpen ? "545px" : "150px",
                    }}
                  >
                    {(props.rightTop === "project1") ? (
                      <div className={`handle-drag position-relative`}>
                        {props?.openProjects?.map((project, index) => {
                          let tempIndex = index + 1;
                          return (
                            <ProjectDetailSection
                              idx={index}
                              id={project.id}
                              addUserAction={{
                                openAddProjectWindow: project?.openAddProjectWindow,
                                id: project.id,
                              }}
                              project_key={project.key}
                              key={project.key}
                              project={project}
                              lastIndex={props.openProjects?.length === tempIndex && index}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {(props.rightTop === "chat1" || props.inActivity) ? (
                      <div className="handle-drag position-relative">
                        {props?.openChats?.map((chat, index) => (
                          <Conversation
                            chat={chat}
                            chat_key={chat.key}
                            key={chat.key}
                            openChatArr={props.openChats}
                          />
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
            :
            <div className="right_content">
              <div className="conversation_top">
                <div
                  className="hscroll project-items"
                  style={{
                    width: "100%",
                    paddingRight: drawerOpen ? "545px" : "150px",
                    paddingLeft: 0,
                    overflowX: "scroll"
                  }}
                >
                  <div className={`handle-drag position-relative`}>
                    {props?.openProjects?.map((project, index) => {
                      let tempIndex = index + 1;
                      return (
                        <ProjectDetailSection
                          idx={index}
                          id={project.id}
                          addUserAction={{
                            openAddProjectWindow: project?.openAddProjectWindow,
                            id: project.id,
                          }}
                          project_key={project.key}
                          key={project.key}
                          project={project}
                          lastIndex={props.openProjects?.length === tempIndex && index}
                          fromActivity={true}
                        />
                      );
                    })}
                  </div>
                  <div className="handle-drag position-relative">
                    {props?.openChats?.map((chat, index) => (
                      <Conversation
                        chat={chat}
                        chat_key={chat.key}
                        key={chat.key}
                        openChatArr={props.openChats}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    projects,
    openProjects,
    openBottomProjects,
    toggleWorkArea,
    rightTop,
    rightBottom,
    openChats,
    openBottomChats,
    groups,
    isOpenByOwner,
    unSeenAllCount,
    groupChats
  } = state;
  return {
    groups: groups,
    projects: projects,
    openProjects: openProjects,
    openBottomProjects: openBottomProjects,
    workArea: toggleWorkArea,
    rightTop: rightTop,
    rightBottom: rightBottom,
    openChats: openChats,
    openBottomChats: openBottomChats,
    isOpenByOwner: isOpenByOwner,
    unSeenAllCount: unSeenAllCount,
    groupChats: groupChats
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProjects: (projects) =>
      dispatch({ type: PROJECTS, projects: projects }),
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateToggleWorkArea: (value) =>
      dispatch({ type: TOGGLE_WORK_AREA, toggleWorkArea: value }),
    updateRightTop: (value) => dispatch({ type: RIGHT_TOP, rightTop: value }),
    updateRightBottom: (value) =>
      dispatch({ type: RIGHT_BOTTOM, rightBottom: value }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
    updateOpenProjectsList: (projects) =>
      dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
    updateBottomOpenProjects: (projects) =>
      dispatch({ type: OPEN_BOTTOM_PROJECTS, openBottomProjects: projects }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
    updateGroupChats: (chats) => dispatch({ type: GROUP_CHATS, groupChats: chats }),
  };
};

export default pure(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(RightBar))
);
