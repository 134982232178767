export const EN = {
  logout: "Logout",
  company_list: "Company List",
  followers: "Followers",
  followings: "Followings",
  article: "Article",
  companies: "Companies",
  one_to_one_chat: " One to One chat",
  profile: "Profile",
  company: "Company",
  home: "Home",
  contact: "Contact ",
  add_company: "Add Company ",
  add_project: "Add Project ",
  capacities: "Capacities ",
  work_area: "Work Area",
  company_services: "Company services",
  website: "Website",
  search_contacts: "Search contacts",
  add_or_join_a_company: "Add or join a company",
  follow: "Follow",
  following: "Following",
  like: "Like",
  company_contacts: "Company contact",
  leave_the_company: "Leave the company",
  news: "News",
  earlier: "Earlier",
  action: "Action",
  another_action: "Another action",
  something_else: "Something else",
  search: "Search",
  contacts: "Contacts",
  suggestions: "Suggestions",
  project_list: "Project list",
  search_results: "Search results",
  add_or_join_project: "Add or join room",
  details: "Details",
  your_position_within_company:
    "Please choose your position within the company.",
  close_modal: "Close",
  map: "Map",
  or: "Or",
  name: "Name*",
  street: "Street*",
  house_number: "House No.",
  postal_code: "Postal Code",
  city: "City*",
  opened_by: "Opened by:",
  project_No: "Project-no: ",
  building_type: "Building Type*",
  building_types: "Building Type",
  add_join: "Add/Join",
  mandatory_fiels: "mandatory fields",
  invalid_fiels: "This field is required",
  add_or_join_company: "Add or join company",
  name_of_the_company: "Name of the company",
  edit_company_details: "Edit company details",
  branch_of_the_building_industry: "Branch of the building industry",
  corporate_form: "Corporate form",
  position_within_company: "Position within the company",
  request_confirmation: "Pending room requests",
  company_e_mail: "Company e-mail",
  type_of_filter: "Type to filter...",
  setting: "Setting",
  dashboard: "Dashboard",
  settings: "Settings",
  views: "Views",
  times_seen: "time seen",
  see_more: "See more",
  see_less: "See less",
  in_search: "in search",
  reset_password: "Reset Password",
  submit: "Submit",
  password: "Password",
  email: "Email",
  confirm_password: "Confirm Password",
  reset_email: "Reset E-Mail",
  update: "Update",
  new_email: "New E-Mail",
  delete_profile: "Delete Profile?",
  my_position: "My Position:",
  add: "add",
  owner_company_admin: "Company admin :",
  owner_company_admin_new: "Company admin",
  mutual_contact: "mutual contact",
  mutual_contacts: "mutual contacts",
  co_owner: "Co-owner",
  managing_director: "Managing Director",
  associate: "Associate",
  secretary: "Secretary",
  employee: "Employee",
  project_manage: "Project Manager",
  auto_chat_translation: "Auto Chat Translation",
  language: "Language",
  english: "English",
  german: "German",
  change_language: "Change Language",
  my_companies: "My Companies",
  employees: "Employees",
  invite_with_a: "Invite to Kjuup",
  add_member: "Add Member",
  add_user: "Add User",
  send_location: "Send Location",
  assign_admin_rights: "Adjust admin right",
  deactivate_company: "Deactivate Company",
  remove_employees: "Remove Employees",
  no_user_found: "No user found!",
  approve: "Approve!",
  deselect_all: "Deselect All",
  translation_on_building_projects: "Translation in rooms",
  reported_capacities: "Reported services",
  up_to_50m_exact: "Up To 50m Exact",
  company_details_service: "Company Services",
  company_contact: "Company Contact",
  invite_with_link: "Invite to Kjuup",
  share_link: "Share Link",
  share_company_link: "Share link",
  qr_code: "Qr Code",
  copy_link: "Copy Link",
  cancel_link: "Cancel Link",
  who_has_confirmed_services: "Who has confirmed your services?",
  projectss: "Rooms",
  add_services: "Add Services",
  services: "Services",
  save_text: "Save",
  share_on: "Share On:",
  articlen: "Article",
  invite_user_to_confirm_service:
    "Invite users to confirm services to the company.",
  link_copied: "Link Copied!",
  email_copied: "Email Copied!",
  personal_article: "Personal Article",
  company_article: "Company Article",
  characters_remaining: "Characters Remaining",
  optional_information: "Optional Information",
  project: "Project",
  projects: "Projects...",
  abort: "Discard",
  preview: "Preview",
  post: "Post",
  of: "of",
  remove: "Remove",
  free_capacity: "Job portal",
  current_status_overview: "Current Status Overview",
  report_capacities: "Report services",
  report_text: "Report",
  search_capacities: "Find contractors",
  add_project_user: "Add Project User",
  media: "MEDIA",
  links: "LINKS",
  docs: "DOCS",
  featured_products: "Featured Products...",
  title: "Title",
  feed_details: "Feed Details",
  add_employee: "Add Employee",
  latest: "Latest",
  my_projects: "My Projects",
  cancel: "Cancel",
  continue: "Continue",
  role_in_the_building_industry: "Role in the Building Industry",
  als: "as",
  join: "join",
  find: "Find",
  request_to_join_a_company: "Request to join the company",
  wants_to_join_the_company: "wants to join the company",
  filter_by: "Filter by",
  ok: "Ok",
  close: "Close",
  weekly_statistics: "Weekly Statistics",
  is_following_you: "is following you.",
  as: "as",
  has_joined_the: "has joined the company",
  has_looked_at_your_profile: "has looked at your profile.",
  time: "time",
  in_searches_in_past_week: "in searches in past week.",
  has_liked_your_article: "has liked your article.",
  has_published_a_new_article: "has published a new article.",
  you: "You",
  all_views: "All Views",
  all_profile_views: "All Profile Views",
  mutual_company: "mutual company",
  artical_text: "Write article...",
  you_have_appeared: "You have appeared",
  your_request_at_the_company: "Your request at the company",
  times_you_have_appeared_in_searches: "times you have appeared in searches",
  appear_more_often_in_searches: "Appear more often in searches",
  you_have_been_found: "You have been found",
  improve_profile: "Improve Profile",
  choose_existing_contact_for_single_chat:
    "Choose existing contact for single chat:",
  in_order_to_add_new_contacts:
    "In order to add new contacts please search for new contacts on the profile page.",
  times_in_searches_in_the_last_week:
    "times in searches in the last week. Update your profile and add further detail in order to be found more easily.",
  has_been_confirmed: "has been confirmed.",
  have_joined_the: "have joined the  ",
  building_project: " building project.",
  select_with_which_company_you_want_to_connect_to_the_project:
    "Select with which company you want to connect to the room.",
  select_with_which_company_you_want_to_connect_to_the_chat:
    "Select with which company you want to connect to the chat.",
  select_profile_image: "Select Profile Image",
  share_user: "Share User",
  contact_sender_message:
    "Share this link to invite a non-member of Kjuup to Kjuup. This link can be used by multiple people to register.",
  project_sender_message:
    "Share this link to invite a non-member of Kjuup to the room project name. This link can be used by multiple people to register and join the room project name in Kjuup.",
  company_sender_message:
    "Share this link to invite a non-member of Kjuup to the company company name. This link can be used by multiple people to register and join the company in Kjuup.",
  contact_receivers_message:
    "Invitation link to join Kjuup. Please click the link to register for kjuup.",
  iphone: "iPhone:",
  iphone_recivers_message:
    "In case you are installing Kjuup via Apple App Store, you must click this link after successful download, to be able to register.",
  project_receivers_message:
    "Invitation link to join the room project name in Kjuup. Please click the link to register for Kjuup and to join the room project name.",
  company_receivers_message:
    "Invitation link to the company company name in Kjuup. Please click on the link to register for Kjuup and to join the company company name.",
  sign_in: "Sign in",
  enter_your_email_add: "Enter your email address",
  forgot_password: "Forgot Password",
  dont_have_account: "Don't have an account ?",
  register: "Register",
  get_app: "Get the app",
  registration: "Registration",
  dep_ing: "Dipl.-Ing.",
  dr: "Dr.",
  dr_ing: "Dr.-Ing.",
  dep_ing_arc: "Dipl.-Ing. Architect",
  prof: "Prof.",
  first_name: "First name",
  last_name: "Last name",
  have_an_account: "Have an account ?",
  when_register_you_agree: "When registering you agree to our",
  term_of_use: "term of use",
  to: "to.",
  in_our: "In our",
  data_policy: "data policies",
  they_find_out: "they find out,",
  we_provide_store_data: "how we collect, process and store information.",
  leaving_company_text:
    "Do you really want to leave the company? When leaving the company you also leave all associated rooms and chat groups. You can re-join these rooms with another company any time.",
  delete_profile_text:
    "Do you really want to delete your profile? When you delete your profile all connections to companies, projects and chats will be removed. You can create a new profile any time.",
  confirm: "Confirm",
  requet_to_leave: "Request to leave",
  choose: "Choose",
  your_add_member: "Add member",
  if_you: "in case you",
  can_not_find: "cannot find your contact here.",
  please_wait: "Please wait..",
  imprint_gtc: "Imprint/GTC",
  register_confirm_popup:
    "Please confirm your email address by clicking the link in the email within the next 48 hours.",
  PENDING_GROUP_USER:
    "will receive messages only after invitation to the room has been confirmed. An additional message will appear once the invitation to the room has been confirmed.",
  PENDING: "Confirmation of invitation to the room pending",
  denied_group_chat: " has denied the room invitation and has left this group.",
  // joined_group_chat: " has joined the room and this group.",
  joined_group_chat: " joined this group",
  you_joined_group_chat: "You joined this group.",
  Others: "Others",
  LEFT_GROUP: "has left this chat group.",
  YOU_LEFT_GROUP: "have left this chat group.",
  days: "days",
  day: "day",
  hour: "hour",
  hide_project: "Hide room",
  hide_project_popup_text:
    "If you hide rooms, they cannot be found within Kjuup. Only members of the room can invite new users to the room.",
  branch: "Branch",
  photo_video_media_lib: "Photo - Video media library",
  document: "Document",
  location_meeting: "Location / Meeting",
  all_member_of_project: "All members of the room",
  pending_request: "Pending room requests",
  no_pending_request: "No pending requests found!",
  news_feed: "News-Feed",
  user_info: "Group Info",
  leave_project: "Leave room",
  media_link_docs: "Media, Links and Docs",
  search_chat: "Search Chat",
  export_chat_group: "Export chat group",
  leave_chat_group: "Leave chat group",
  add_user_project: "Add User",
  me: "Me",
  max_open_one_to_one:
    "Currently max. 2 one-on-one chats can be opened in parallel on bottom - and top section. In total max. 4 one-on-one chats are possible.",
  are_you_sure_you_want_leave_chat:
    "Are you sure, you want to leave this chat group?",
  create_group: "Create Group",
  group_name: "Group Name",
  by: "Von:",
  mandetory: "*mandatory",
  delteed: "has deleted his/her profile and has left the group chat.",
  unhidden_message:
    "If you unhide rooms, they can be found by all members of Kjuup.",
  account_deleted: "Account has been deleted.",
  refuse_company: "Are you sure you want to refuse the company invitation?",
  follow_additional: "Follow additional users",
  f_add:
    "Follow additional users of Kjuup to make them available for direct messages.",
  c_inv: "Company invitation(s)",
  invited_o_Company: "You have been invited to join the company",
  refuse_companyX: "Are you sure you want to refuse the company request?",
  a_Da: "Account deactivated.",
  has_deactivated: "has deactivated his/her profile.",
  reactive: "has reactivated his/her profile.",
  juup_note: "*Registration with username only available for members of Kjuup+",
  username: "Username*",
  email_username: "Email/Username*",
  sPinText:
    "Please provide a 6-digit security pin. This pin will be needed in case you forget your password and is mandatory to confirm your account.",
  DeleteKjuupPlusText:
    "Do you really want to delete your profile? When you delete your profile all connections to companies, projects and chats will be removed.\n\nWithin 48h after account deletion you can reactivate your account by signing in with username, password and pin.",
  reactive_text:
    "Your account has been deleted. Please click on Reactivate to reactivate your account.",
  rac: "Reactivate",
  ac_ss: "Account reactivated successfully.",
  not_valid_pin: "Pin invalid",
  only_for_kjuup:
    "*Password reset via username and pin only available for Kjuup+ members.",
  resetPIN: "Reset PIN",
  enter_current_pin: "Please enter your current PIN",
  enter_new: "Enter new PIN",
  pin_to_confirm: "Please provide the PIN to confirm.",
  change_username: "Reset Username",
  pp_not_match: "Password - and Confirm Password don’t match",
  reactive_itself: "You have reactivated your profile.",
  has_deactivated_itself: "You have deactivated your profile.",
  more_than_two:
    "Currently direct messages can be only done with 2 users in parallel.",
  Chats: "Direct Messages",
  only_in_mobileL:
    "Native videos can currently only be uploaded and posted via mobile App. Please use video links of Youtube and Vimeo.",
  register_pin_text:
    "Please choose your personal 6-digit PIN. This PIN will be needed to enable account reset functionalities.",
  internal_project: "Internal",
  external_projects: "External",
  in_deacticted: "Internal section is deactivated.",
  new_article_selection: "Create article for external News-Feed",
  new_article_selection_internal: "Create in-house article",
  active_trans:
    "Do you really want to activate automatic translation? All incoming messages in chats will then be automatically translated into the language used in the browser.Only available on group chats on projects!",
  disable_trans: "Do you really want to deactivate automatic translation?",
  allow_access_chat: "Show full chat history",
  toggle_chat_history_on:
    "Do you really want the user to see the full chat history?",
  toggle_chat_history_off:
    "Do you really want the user to only see the chat history from the point of joining the chat?",
  check_selected_settings:
    "Please check the settings for chat history for all selected users!",
  chat_from_start_have:
    "You have joined this group. The chat history has been shared.",
  chat_from_start_has:
    "has joined this group. The chat history has been shared.",
  Address: "Address",
  read_by: "Read by",
  deliver_to: "Delivered to",
  remaining: "remaining",
  message_info: "Message Info",
  request_to_join_only: "Request to join",
  unfollow: "Unfollow",
  company_username: "Username / Company name",
  month: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  remove_my_admin_right: "Remove my admin rights",
  makeEmp: "Do you really want to remove company admin rights from the user?",
  remove_my_self:
    "Do you really want to remove company admin rights from your account?",
  makeAdmin: "Do you really want to give the user company admin rights?",
  remove_member: "Remove Member",
  do_you_really_want_to_remove:
    "Do you really want to remove username from the company?",
  invite_all: "Invite all members of the company",
  invite_all_members: "Invite all members",
  mems: "All other members",
  dep_loc: "Department/Location/Team",
  depAdmin: "Department Admin",
  add_remove_admin: "Add/Remove company admin position",
  add_remove_dep_admin: "Add/Remove department admin position",
  remove_employees_dept: "Remove Employees From",
  want_to_join_dep:
    "Username wants to join the Department/Location/Team depname of the company companyname.",
  you_invited:
    "You have been invited to join the Department/Location/Team depname of the company companyname.",
  dep_remove:
    "Do you really want to remove admin rights for department or location from the user?",
  add_dep:
    "Do you really want to give the user admin rights for the department or location?",
  my_self_dep_remove:
    "Do you really want to remove department admin rights from your account?",
  company_sender_message_dep:
    "Invitation link to the depname of the company company name in Kjuup. Please click on the link to register for Kjuup and to join the company company name.",
  member: "Member",
  admin: "Admin",
  remove_project_admin:
    "Do you really want to remove the member from the room?",
  remove_my_self_project: "Do you really want to remove admin rights?",
  remove_member_admin_right:
    "Do you really want to remove admin rights from the user?",
  add_member_admin_right: "Do you really want to add admin rights to the user?",
  add_remove_Poss: "Add/Remove admin position",
  forward: "Forward",
  edit: "Edit",
  only_kjuup_plus_can: "Only Kjuup+ companies can post articles.",
  add_images: "Add image(s)",
  request_confirmation: 'Pending room invitations',
  Activity: "Activity",
  latest_messages: "Latest messages",
  hours: "hours",
  minute: "minutes",
  seconds: "seconds",
  days: "days",
  yesterday: "yesterday",
  last_week: "last week",
  next_week: "next week",
  Last_month: "last month",
  All: "All",
  register_plus: "Registration Plus",
  select_files: "Select files",
  monthsFullName: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  company_deploc_receivers_message:
    "Invitation link to the department of the company company name in Kjuup. Please click on the link to register for Kjuup and to join the company company name.",
  no_news: "No news available",
  reminder: "Reminder",
  np_reminder: "No Reminders",
  move_to_reminder: "Move to Reminder",
  remove_reminder:
    "Do you really want to remove this message from the list of your Reminders?",
  remove_all_reminder: "Remove all Reminders",
  remove_all_confirm:
    "Do you really want to remove all messages from the list of your Reminders?",
  remove_from_reminder: "Remove from Reminder",
  prevent_email: "E-Mail cannot be used as Username.",
  allow_noti: "Please allow notifications.",
  join_cap: "Join",
  pass_minimum:
    "**Password requires 12 characters incl. capital letter, number and special character.",
  pass_error: "Please check password requirements",
  people_that_like_article: "People that like the article.",
  people_who_like_article: "People who like your article.",
  message_delete: "This message has been deleted.",
  link_invite: "Invite via link",
  ll_nkk: "Invite to Kjuup",
  selected: "Selected",
  is_active: "Active",
  inactive: "Inactive",
  remove_user_l: "Remove",
  link_expire: "Each generated Link will be valid for 72 hours.",
  company_language: "Company language",
  company_language_info:
    "The language selection will be used for all email confirmations concerning the company.",
  s_image: "image",
  m_image: "images",
  removeMsg: "Tap to remove",
};
