import React, { createContext } from "react";
import { Form, Nav, Tab } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import Layout from "../../components/Layout";
import { callAPI } from "../../helper/apiUtils";
import { UserFeed } from "./UserFeed";
import { CompanyFeed } from "./CompanyFeed";
import ArticleFilter from "./Dialogs/ArticleFilter";
import { ReactComponent as ContactIcon } from "../../Icons/ContactIcon.svg";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyIcon.svg";
import { ReactComponent as BranchIcon } from "../../Icons/BranchIcon.svg";
import ArticleRole from "./Dialogs/ArticleRoles";
import ApproveUsers from "./Dialogs/ApproveUsers";
import { debounce } from "lodash";
import { $crud } from "../../helper/CrudFactory";
import FeedViewDialog from "./Dialogs/FeedViewDialog";
import { connect } from "react-redux";
import { ARTICLE, SET_COMPANY_ID, SET_COMPANY_IMAGE, SET_TAB } from "../../store";
import { $user } from "../../helper/UserFactory";
import { Animated } from "react-animated-css";
import { FilterRoles } from "../../Languages/vars/FilterRoles";
import { Scrollbars } from 'react-custom-scrollbars';
import { async } from "@firebase/util";
import ReactList from 'react-list';
import Virtual from 'react-virtual-dynamic-list'
import ArticleCard from "../../components/ArticleCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import { FixedSizeList as List } from "react-window";
import { ViewportList } from 'react-viewport-list';
import IGRENN from '../../Icons/InternalGreen.png';
import INormal from '../../Icons/Internal-Project.png';
import ExternalOrange from '../../Icons/ExternalOrange.png';
import ExternalNormal from '../../Icons/External-Project.png';
import Tabs from '@mui/material/Tabs';
import ReactTab from '@mui/material/Tab';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { KJUUP_COLORS } from "../../helper/constants";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import CommonHeader from "../../components/CommonHeader";


function a11yProps(index, value) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: {
      // backgroundColor: (index == 0 && value == index) ? "yellow" : (index == 1 && value == index) ? "blue" : "white",
      color: (index == 1 && value == index) ? "#288f6c" : (index == 0 && value == index) ? "#f08438" : "grey",
      borderWidth: 0,
      padding: 0,
      textTransform: "none",
      fontSize: "1.8vh",
      bottom: 7
    }
  };
}

let self;

class Articles extends React.Component {
  // static contextType = { ...LanguageContext,...VisibilityContext };
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch.bind(this), 100);
    this.myRef = React.createRef();
    this.internalRef = React.createRef();
    this.customRef = React.createRef();
    this.scrollRef = React.createRef();
    this.state = {
      loadingArticle: false,
      limit: 15,
      page: 1,
      query: "",
      queryInternal: "",
      scrollHeight: 500,
      hasMore: true,
      articles: [],
      emoji: false,
      openFilter: false,
      showRoleDialog: false,
      followings: false,
      followers: false,
      sector: [],
      openRequestDialog: false,
      companyRequests: [],
      viewFeed: false,
      rolesData: FilterRoles,
      totalArticles: null,
      value: 0,
      internalArticles: [],
      internalArticlesAll: [],
      showTabs: false,
      isExternalSelected: 0,
      selectedCompanyId: null,
      companies: []
    };
  }

  getProfile = async () => {
    const { data } = await callAPI(`/company/my`, {}, "GET");
    if (data.status) {
      this.setState({ companies: data.companies })
    }
  };

  static getDerivedStateFromProps(props, state) {
    if ((props.isExternalSelected !== state.isExternalSelected && state.internalArticles.length > 0) || props.selectedCompanyId !== state.selectedCompanyId) {
      let filteredArticles = state.internalArticles.map((e, i) => {
        if (props.selectedCompanyId !== null)
          return { ...e, isSelected: (e.id == props.selectedCompanyId) ? true : false }
        else
          return { ...e, isSelected: i == 0 ? true : false }
      })
      let ind = filteredArticles.findIndex(e => e.isSelected)
      if (ind !== -1) {
        if (self && self.customRef && self.customRef.current) {
          self.customRef.current.scrollToItem(
            self.customRef.current.getItemById(filteredArticles[ind].id),
            // OR if you not sure about id for first item
            // myRef.current.getItemById(myRef.current.items.toItems()[0]),
            "smooth",
            "center"
          );
        }
      }
      if (filteredArticles.length > 1) {
        return {
          isExternalSelected: props.isExternalSelected,
          value: props.isExternalSelected,
          selectedCompanyId: props.selectedCompanyId,
          internalArticles: [...filteredArticles],
          internalArticlesAll: [...filteredArticles]
        }
      }
      else {
        let tmp = [...filteredArticles]
        if (tmp && tmp.length && tmp.length > 0)
          tmp[0].isSelected = true
        return {
          isExternalSelected: props.isExternalSelected,
          value: props.isExternalSelected,
          selectedCompanyId: props.selectedCompanyId,
          internalArticles: [...tmp],
          internalArticlesAll: [...tmp]
        }
      }
    }
    else {
      return null
    }
  }


  setParam = async (key, value) => {
    await this.setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  get = async () => {
    const { page, limit, query } = this.state;
    const { data } = await callAPI(
      "/article/all",
      {
        page,
        limit,
        query,
        lang: this.context.isGerm ? "de" : "en",
      },
      "GET"
    );
    if (data.status) {
      const updatedArticles = data.articles.map((article) => {
        article.expand = false;
        return article;
      });

      await this.setState({
        articles: [...this.state.articles, ...updatedArticles],
        totalArticles: data?.totalRecords
      });
    } else {
      this.setState({ hasMore: false });
    }
  };

  getInternal = async (ind) => {
    const { pageInternal, limitInternal, queryInternal } = this.state;
    let sindex = (ind || ind == 0) ? ind : 0
    let { data } = await callAPI(
      "/company/getInternalArticle",
      {
        pageInternal,
        limitInternal,
        queryInternal,
        lang: this.context.isGerm ? "de" : "en",
      },
      "GET"
    );
    console.log('------->Internal Projects', data)
    if (data.status) {
      if (data.articles && data.articles.length > 0) {
        let filteredArticles = data.articles.map((e, i) => {
          if (this.props.selectedCompanyId !== null)
            return { ...e, isSelected: (e.id == this.props.selectedCompanyId) ? true : false }
          else
            return { ...e, isSelected: i == 0 ? true : false }
        })
        if (data.articles.length > 1) {
          data.articles = [...filteredArticles]
        }
        else {
          data.articles[0].isSelected = true
        }
        const updatedArticles = data.articles.map((article) => {
          article.expand = false;
          return article;
        });

        if (updatedArticles && updatedArticles.length && updatedArticles.length > 0) {
          this.setState({ showTabs: true })
        }
        else {
          this.setState({ value: 1, showTabs: false })
        }

        await this.setState({
          internalArticles: [...this.state.internalArticles, ...updatedArticles],
          totalArticlesInternal: data?.totalRecords,
        }, () => {
          this.setState({ internalArticlesAll: data.articles }, () => {
            let ind = filteredArticles.findIndex(e => e.isSelected)
            console.log('----ind', ind)
            if (ind !== -1) {
              setTimeout(() => {
                if (this.customRef.current) {
                  this.customRef.current.scrollToItem(
                    this.customRef.current.getItemById(filteredArticles[ind].id),
                    // OR if you not sure about id for first item
                    // myRef.current.getItemById(myRef.current.items.toItems()[0]),
                    "smooth",
                    "center"
                  );
                }
              }, 500);

            }
          })
        });
      }
      else {
        this.setState({ value: 0, showTabs: false })
      }
    } else {
      this.setState({ hasMoreInternal: false, value: 0, showTabs: false });
    }
  };

  getCompanyRequests = async () => {
    const { data } = await callAPI(
      "/comapny/getUserCompanyRequests",
      {},
      "GET"
    );
    if (data.companyRequests && data.companyRequests.length > 0) {
      await this.setState({ companyRequests: data.companyRequests });
      this.setState({ openRequestDialog: true });
    }
  };

  fetchMore = async () => {
    // console.log("this.state.hasMore=>",this.state.hasMore)
    this.setState({ loadingArticle: true });
    if (this.state.hasMore) {
      await this.filter(false, true)
      // const { page, limit, query } = this.state;
      // const { data } = await callAPI(
      //   "/article/all",
      //   { page, limit, query },
      //   "GET"
      // );
      // if (data.status) {
      // const updatedArticles = data.articles.map((article) => {
      //   article.expand = false;
      //   return article;
      // });
      // await this.setState((state) => {
      //   return {
      //     ...state,
      //     articles: [...state.articles, ...updatedArticles],
      //   };
      // });
      // } else {
      //   this.setState({ hasMore: false, loadingArticle: false });
      // }
    }
    await this.setState({ loadingArticle: false });
  };

  filter = async (isSelector = false, isHasMore = false) => {
    this.setState({ loadingArticle: true });
    const { emoji, page, limit, followings, followers, sector } = this.state;
    // console.log("sector==>",sector)
    let filterString = "";
    filterString = followers ? "followers" : "";
    filterString += followings ? ",followings" : "";
    filterString += emoji ? ",emoji" : "";
    filterString += sector.length > 0 ? ",sector" : "";
    const sectors = sector.length > 0 ? sector.join(",") : "";
    if (filterString) {
      const { data } = await callAPI(
        "/article/filter",
        "",
        "POST",
        {
          "flags": filterString,
          sector: sectors,
          page: page > 0 ? page : 0,
          limit,
          lang: this.context.isGerm ? "de" : "en"
        },
      );
      if (data.status) {
        if (data?.articles?.length < 1) {
          // console.log("claed")
          this.setState({ hasMore: false, loadingArticle: false });
        } else {
          if (isHasMore) {
            const updatedArticles = data.articles.map((article) => {
              article.expand = false;
              return article;
            });
            await this.setState((state) => {
              return {
                ...state,
                articles: [...state.articles, ...updatedArticles],
              };
            });
            await this.setState({ loadingArticle: false });
          } else {
            this.setState({ articles: data.articles });
          }
        }
      }
    } else {
      this.get();
      this.setState({ hasMore: true, loadingArticle: false });
    }
    this.setState({ loadingArticle: false });
  };

  setSector = async (sectors) => {
    await this.setParam("sector", sectors);
    this.setState({ articles: [] })
    this.filter(true);
  };

  handleEmoji = async () => {
    await this.setParam("emoji", !this.state.emoji);
    this.filter();
  };

  handleScroll = async () => {
    // console.log("Scroll caaled")
    const element = this.myRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      const page = this.state.page;
      const newPage = page + 1;
      await this.setState((state) => {
        return {
          ...state,
          page: newPage,
        };
      });
      if (this.state.articles.length < this.state.totalArticles) {
        await this.fetchMore();
      } else {
        this.setState({ hasMore: false })
      }
    }
  };

  _increasePageNumber = async () => {
    const page = this.state.page;
    const newPage = page + 1;
    await this.setState((state) => {
      return {
        ...state,
        page: newPage,
      };
    });
    if (this.state.articles.length < this.state.totalArticles) {
      await this.fetchMore();
    } else {
      this.setState({ hasMore: false })
    }
  }

  getSavedArticles = () => {
    let savedArticles = localStorage.getItem("savedArticles")
      ? JSON.parse(localStorage.getItem("savedArticles"))
      : [];
    const foundIndex = savedArticles.findIndex(
      (article) => article?.userId === this.props.user?.id
    );
    if (foundIndex > -1) {
      this.setState({ articles: savedArticles[foundIndex].articles });
    }
  };
  goToViolation = (id) => {
    const violation = document.getElementById(id);
    violation.scrollIntoView()
  }

  componentDidMount() {
    // this.setState({ articles: new Array(3).fill({ adminId: "" }) });
    // this.getSavedArticles();
    self = this;
    this.getProfile()
    if (this.props?.location?.state?.isPosted) {
      if (this.props?.location?.state?.companyIndex || this.props?.location?.state?.companyIndex == 0) {
        this.setState({ value: 0 })
      }
      else {
        this.setState({ value: 1 })
      }
    }
    this.get();
    this.getInternal(this.props?.location?.state?.companyIndex)
    this.getCompanyRequests();
  }


  componentWillUnmount() {
    this.setState({ value: this.props.isExternalSelected })
    const articleObj = {
      userId: this.props.user?.id,
      articles: this.state.articles,
    };
    let savedArticles = localStorage.getItem("savedArticles")
      ? JSON.parse(localStorage.getItem("savedArticles"))
      : [];
    const foundIndex = savedArticles?.findIndex((msg) => msg.userId === this.props.user?.id);
    if (foundIndex > -1) {
      savedArticles[foundIndex] = {
        ...savedArticles[foundIndex],
        articles: this.state.articles,
      };
    } else {
      savedArticles = [...savedArticles, articleObj];
    }
    localStorage.setItem("savedArticles", JSON.stringify(savedArticles));
  }

  expandArticle = (key) => {
    const articles = this.state.articles;
    let article = { ...articles[key] };
    article.expand = !article.expand;
    articles[key] = { ...article };
    this.setState({ articles });
  };
  handleFollow = async (id, followStatus) => {
    if (this.state.value == 0) {
      const articleDetails = await this.state.articles.find(
        (article) => article.id === id
      );
      const articlesList = this.state.articles.map((article) => {
        if (article.createdById === articleDetails.createdById) {
          article.followStatus = followStatus ? false : true;
        }
        return article;
      });
      this.setState({ articles: articlesList });
    }
    else {
      let { internalArticles } = this.state
      let ind = internalArticles.findIndex(e => e.isSelected)
      for (let obj of internalArticles[ind].companyArticle) {
        if (obj.userId == id) {
          obj.userFollowStatus = !obj.userFollowStatus
        }
      }
      this.setState({ internalArticles })
    }
  };
  handleLike = async (id) => {
    if (this.state.value == 0) {
      const articleDetails = await this.state.articles.find(
        (article) => article.id === id
      );
      const articlesList = this.state.articles.map((article) => {
        if (article.id === articleDetails.id) {
          article.likeStatus = !articleDetails.likeStatus;
          const existUser = article.likes.find((like) => like.id === this.props.user?.id);
          if (existUser) {
            article.likes = article.likes.filter((like) => like.id !== this.props.user?.id);
            article.likeCount = articleDetails.likeCount - 1;
          } else {
            article.likes = [
              ...article.likes,
              { id: this.props.user?.id, avatar: this.propsavatar },
            ];
            article.likeCount = articleDetails.likeCount + 1;
          }
        }
        return article;
      });
      this.setState({ articles: articlesList });
    }
    else {
      let { internalArticles } = this.state
      let ind = internalArticles.findIndex(e => e.isSelected)
      const articleDetails = await internalArticles[ind].companyArticle.find(
        (article) => article.id === id
      );
      const articlesList = internalArticles[ind].companyArticle.map((article) => {
        if (article.id === articleDetails.id) {
          article.likeStatus = !articleDetails.likeStatus;
          const existUser = article.likes.find((like) => like.id === this.props.user?.id);
          if (existUser) {
            article.likes = article.likes.filter((like) => like.id !== this.props.user?.id);
            article.likeCount = articleDetails.likeCount - 1;
          } else {
            article.likes = [
              ...article.likes,
              { id: this.props.user?.id, avatar: this.propsavatar },
            ];
            article.likeCount = articleDetails.likeCount + 1;
          }
        }
        return article;
      });
      internalArticles[ind].companyArticle = articlesList
      this.setState({
        internalArticles: internalArticles
      })
    }
  };

  setRollData = (data) => {
    this.setState({
      rolesData: data
    })
  }

  handleSearch = async (text) => {
    this.setState({ query: text });
    if (text) {
      const { page, limit } = this.state;
      const { data } = await callAPI(
        "/article/all/search",
        { page, limit, query: text },
        "GET"
      );
      if (data.status) {
        const updatedArticles = data.articles.map((article) => {
          article.expand = false;
          return article;
        });
        this.setState({ articles: updatedArticles });
        this.setState({ hasMore: false });
      } else {
        $crud.notify({
          type: "error",
          message: data.message,
        });
        this.setState({ articles: [] });
      }
    } else {
      this.get();
    }
  };

  renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
  };


  CustomScrollbars = props => (
    <Scrollbars
      renderThumbHorizontal={this.renderThumb}
      renderThumbVertical={this.renderThumb}
      {...props}
      onScrollFrame={(e) => {
        if (e.top >= 0.98) {
          this._increasePageNumber()
        }
      }}
    />
  );

  CustomScrollbarsInternal = props => (
    <Scrollbars
      renderThumbHorizontal={this.renderThumb}
      renderThumbVertical={this.renderThumb}
      {...props}
      onScrollFrame={(e) => {
        if (e.top >= 0.98) {
          // this._increasePageNumber()
        }
      }}
    />
  );


  handleChange = (newValue) => {
    this.props.changeTab(newValue)
    this.setState({ value: newValue })
  }


  _onCompanyPress = (index, id) => {
    this.customRef.current.scrollToItem(
      this.customRef.current.getItemById(id),
      // OR if you not sure about id for first item
      // myRef.current.getItemById(myRef.current.items.toItems()[0]),
      "smooth",
      "center"
    );
    let tmp = [...this.state.internalArticles]
    for (let i = 0; i < tmp.length; i++) {
      if (i == index) {
        if (tmp[i].isLeave) {
          this.props.setCompanyImage(null)
        }
        else
          this.props.setCompanyImage(tmp[i].logo)
        this.props.setCompanyId(tmp[i].id)
        tmp[i].isSelected = true
      }
      else {
        tmp[i].isSelected = false
      }
    }
    this.setState({ internalArticles: tmp })
  }


  LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } = this.customRef.current
    return (
      <div className="tt-l mx-1" disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        {isFirstItemVisible ?
          // <img
          //   width={10} src={process.env.PUBLIC_URL + "assets/img/Arrow Left-deselected.svg"} alt="Generic placeholder"
          // />
          <BsChevronLeft size={'20px'} color={"#DFDFDF"} />
          :
          <BsChevronLeft size={'20px'} color={KJUUP_COLORS.internal} />
          // <img width={10} src={process.env.PUBLIC_URL + "assets/img/backx.svg"} alt="Generic placeholder" />
        }
      </div>
    );
  }

  RightArrow = () => {
    const { isLastItemVisible, scrollNext } = this.customRef.current
    return (
      <div className="tt-l mx-1" disabled={isLastItemVisible} onClick={() => scrollNext()}>
        {isLastItemVisible ?
          // <img
          //   width={10} src={process.env.PUBLIC_URL + "assets/img/arrow-rightdeselected.svg"} alt="Generic placeholder"
          // />
          <BsChevronRight size={'20px'} color={"#DFDFDF"} />
          :
          <BsChevronRight size={'20px'} color={KJUUP_COLORS.internal} />
        }
      </div>
    );
  }

  _searchForInternalArticles = async (val) => {
    this.setState({ queryInternal: val }, async () => {
      let { internalArticles, internalArticlesAll } = this.state
      let ind = internalArticles.findIndex(e => e.isSelected)
      const { data } = await callAPI(
        "/company/getInternalArticle",
        {
          // pageInternal,
          // limitInternal,
          search: val,
          lang: this.context.isGerm ? "de" : "en",
          company_id: internalArticles[ind].id
        },
        "GET"
      );
      if (data.status) {
        let res = data.articles
        let ii = res.findIndex(e => e.id == internalArticles[ind].id)
        let tmp = [...internalArticles]
        tmp[ind].companyArticle = res[ii].companyArticle
        this.setState({
          internalArticles: tmp
        })
      }
    })

  }


  _renderInternal = () => {
    return (
      <div>
        {this.state.internalArticles.length >= 2 &&
          <div style={{ marginTop: 5 }} />
        }
        {this.state.internalArticles.length <= 2 ?
          this.state.internalArticles.length == 1 ?
            <></>
            :
            <ScrollMenu apiRef={this.customRef}>
              {
                this.state.internalArticles.map((item, index) => {
                  return (
                    <div
                      id={item.id}
                      itemId={item.id}
                      onClick={() => this._onCompanyPress(index, item.id)}
                      className="tt-ll"
                      style={{
                        height: 40,
                        width: 200,
                        marginLeft: index == 0 ? 5 : 10,
                        borderColor: item.isLeave ? "lightgray" : item.isSelected ? "#288f6c" : "gray",
                        backgroundColor: item.isLeave ? "#f2f2f2" : item.isSelected ? "#d7f4ea" : "white",
                        fontWeight: "500",
                        // opacity: item.isLeave ? 0.5 : 1,
                        color: item.isLeave ? "white" : "#000"
                        // backgroundColor: "red"
                      }}>
                      {item.name}
                    </div>
                  )
                })
              }
            </ScrollMenu>
          : <ScrollMenu apiRef={this.customRef} LeftArrow={this.LeftArrow} RightArrow={this.RightArrow}>
            {
              this.state.internalArticles.map((item, index) => {
                return (
                  <div
                    id={item.id}
                    itemId={item.id}
                    onClick={() => this._onCompanyPress(index, item.id)}
                    className="tt-ll"
                    style={{
                      height: 40,
                      width: 200,
                      marginLeft: index == 0 ? 0 : 20,
                      borderColor: item.isLeave ? "#000" : item.isSelected ? "#288f6c" : "gray",
                      backgroundColor: item.isLeave ? "#f2f2f2" : item.isSelected ? "#d7f4ea" : "white",
                      fontWeight: "500",
                      opacity: item.isLeave ? 0.5 : 1
                    }}>
                    {item.name}
                  </div>
                )
              })
            }
          </ScrollMenu>}

        {
          this.state.internalArticles.map((mainItem, mainIndex) => {
            return (
              mainItem.isSelected &&
              <div style={{ backgroundColor: "#F8F8F8" }}>
                {/* <div style={{ display: "flex", marginBottom: -20, marginTop: -5, paddingLeft: 10, paddingRight: 10 }}>
                  <div className="search" style={{ flex: 9 }}>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="search"
                        value={this.state.queryInternal}
                        onChange={(e) =>
                          this._searchForInternalArticles(e.target.value)
                        }
                        placeholder={
                          this.context.isGerm
                            ? DE.search
                            : EN.search
                        }
                        style={{ height: '35px' }}
                        className="article_search_internal"
                      />
                    </Form.Group>
                    <img
                      style={{ height: 20, width: 20 }}
                      className="news_t_ser"
                      src={
                        process.env.PUBLIC_URL +
                        "assets/img/search.svg"
                      }
                      alt="Generic placeholder"
                    />
                  </div>
                  {(this.state.internalArticles[this.state.internalArticles.findIndex(e => e.isSelected)].degination == 'COMPANY ADMIN' || this.state.internalArticles[this.state.internalArticles.findIndex(e => e.isSelected)].degination == 'COMPANY_DEPARTMENT_ADMIN') ?
                    <div style={{ justifyContent: "center", alignItems: "center", display: 'flex' }}>
                      <Link to={
                        {
                          pathname: `/add-company-article/${mainItem.id}`,
                          state: { from: "internal", articleType: 1, companyId: mainItem.id, companyIndex: mainIndex }
                        }
                      }>
                        <img
                          height={30}
                          width={30}
                          src={
                            process.env.PUBLIC_URL +
                            "assets/img/green_create.png"
                          }
                          alt="Generic placeholder"
                          style={{ marginLeft: 10, marginBottom: 20 }}
                        />
                      </Link>
                    </div>
                    :
                    <></>
                  }
                </div> */}
                <div
                  className="scroll"
                  onScroll={(e) => {
                    const element = this.myRef.current;
                    if (element?.scrollHeight - element?.scrollTop === element?.clientHeight) {
                      // this._increasePageNumber()
                    }
                  }}
                  ref={this.internalRef}
                  style={{ height: "87vh" }}
                >
                  <ViewportList
                    viewportRef={this.internalRef}
                    items={mainItem.companyArticle}
                    withCache
                  >
                    {(article, key) => (
                      <div
                        key={key}
                        className="media-desc"
                        // style={{ height: "auto !important" }}
                        id={article.id}
                      >
                        {article.createdBy === "user" ? (
                          <UserFeed
                            viewArticleDetail={this.props.viewArticle}
                            handleFollow={this.handleFollow}
                            handleLike={this.handleLike}
                            reloadArticle={() => this.get()}
                            index={key}
                            article={article}
                            backId={this.props.location.state}
                            expandArticle={this.expandArticle}
                            isInternal={true}
                          />
                        ) : (
                          <CompanyFeed
                            viewArticleDetail={this.props.viewArticle}
                            handleLike={this.handleLike}
                            handleFollow={this.handleFollow}
                            reloadArticle={() => this.get()}
                            index={key}
                            article={article}
                            backId={this.props.location.state}
                            expandArticle={this.expandArticle}
                            isInternal={true}
                          />
                        )}
                      </div>
                    )}
                  </ViewportList>
                </div>
              </div>
            )
          })
        }

      </div>
    )
  }

  _renderNoraml = () => {
    let haveKjuup = this.state.companies.filter(e => e.isKjuup)
    return (
      <div style={{ flex: 1, display: "flex", flexDirection: 'column' }}>
        {/* <div style={{ display: "flex", marginBottom: -20, marginTop: -5, paddingLeft: 10, paddingRight: 10 }}>
          <div className="search" style={{ flex: 9 }}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                type="search"
                value={this.state.query}
                onChange={(e) =>
                  this.handleSearch(e.target.value)
                }
                placeholder={
                  this.context.isGerm
                    ? DE.search
                    : EN.search
                }
                className="article_search"
                style={{ height: '35px' }}
              />
            </Form.Group>
            <img
              style={{ height: 20, width: 20 }}
              className="news_t_ser"
              src={
                process.env.PUBLIC_URL +
                "assets/img/search.svg"
              }
              alt="Generic placeholder"
            />
          </div>
          {haveKjuup.length > 0 ?
            <div style={{ justifyContent: "center", alignItems: "center", display: 'flex' }}>
              <Link to={
                {
                  pathname: `/companies`,
                  state: { articleType: 0 }
                }
              }>
                <img
                  height={30}
                  width={30}
                  src={
                    process.env.PUBLIC_URL +
                    "assets/img/CreateArticle.svg"
                  }
                  alt="Generic placeholder"
                  style={{ marginLeft: 10, marginBottom: 20 }}
                />
              </Link>
            </div>
            :
            <div onClick={() => {
              $crud.notify({
                type: "error",
                message: this.context.isGerm ? DE.only_kjuup_plus_can : EN.only_kjuup_plus_can
              })
            }} style={{ justifyContent: "center", alignItems: "center", display: 'flex' }}>
              <img
                height={30}
                width={30}
                src={
                  process.env.PUBLIC_URL +
                  "assets/img/create-article-color.svg"
                }
                alt="Generic placeholder"
                style={{ marginLeft: 10, marginBottom: 20 }}
              />
            </div>
          }
        </div> */}
        <div
          className="scroll"
          onScroll={(e) => {
            const element = this.myRef.current;
            let bottom = parseInt(element?.scrollHeight - element?.scrollTop) <= parseInt(element?.clientHeight)
            if (bottom) {
              this._increasePageNumber()
            }
          }}
          ref={this.myRef}
          style={{ height: '87vh', overflowX: "hidden" }}
        >
          <ViewportList
            viewportRef={this.myRef}
            items={this.state.articles}
            withCache
          >
            {(article, key) => (
              <div
                key={key}
                className="media-desc"
                // style={{ height: "auto !important" }}
                id={article.id}
              >
                {article.createdBy === "user" ? (
                  <UserFeed
                    viewArticleDetail={this.props.viewArticle}
                    handleFollow={this.handleFollow}
                    handleLike={this.handleLike}
                    reloadArticle={() => this.get()}
                    index={key}
                    article={article}
                    backId={this.props.location.state}
                    expandArticle={this.expandArticle}
                  />
                ) : (
                  <CompanyFeed
                    viewArticleDetail={this.props.viewArticle}
                    handleLike={this.handleLike}
                    handleFollow={this.handleFollow}
                    reloadArticle={() => this.get()}
                    index={key}
                    article={article}
                    backId={this.props.location.state}
                    expandArticle={this.expandArticle}
                  />
                )}
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    )
  }



  render() {
    let { value, showTabs } = this.state
    return (
      <React.Fragment>
        <Layout>
          <Animated animationIn="slideInLeft" isVisible={true}>
            <div className="news-section w-300 border-radius-0"
              style={{
                borderColor: value == 1 ? "#288f6c" : "#f08438",
                height: "93vh",
                borderTopColor: value == 1 ? "#288f6c" : "#f08438",
                borderTop: `2px solid ${value == 1 ? "#288f6c" : "#f08438"}`,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CommonHeader
                value={value}
                onChange={(val) => this.handleChange(val)}
                isGerm={this.context.isGerm}
                searchQuery={value == 0 ? this.state.query : this.state.queryInternal}
                onSearch={(val) => {
                  if (value == 0)
                    this.handleSearch(val)
                  else
                    this._searchForInternalArticles(val)
                }}
                showPlus={value == 1 ?
                  (this.state.internalArticles[this.state.internalArticles.findIndex(e => e.isSelected)]?.degination == 'COMPANY ADMIN' || this.state.internalArticles[this.state.internalArticles.findIndex(e => e.isSelected)]?.degination == 'COMPANY_DEPARTMENT_ADMIN') :
                  this.state.companies.filter(e => e.isKjuup).length > 0
                }
                onAddClick={() => {
                  if (value == 1) {
                    let mainIndex = this.state.internalArticles.findIndex(e => e.isSelected)
                    let mainItem = this.state.internalArticles[mainIndex]
                    this.props.history.push(
                      {
                        pathname: `/add-company-article/${mainItem.id}`,
                        state: { from: "internal", articleType: 1, companyId: mainItem.id, companyIndex: mainIndex }
                      })
                  }
                  else {
                    this.props.history.push({
                      pathname: `/companies`,
                      state: { articleType: 0 }
                    });
                  }
                }}
                type={'feed'}
              />
              {/* <div>
                {showTabs ?
                  <div className="main_heading" style={{ marginBottom: 0 }}>
                    <Tabs
                      variant="fullWidth"
                      value={value}
                      onChange={this.handleChange}
                      TabIndicatorProps={{ style: { background: value == 1 ? "#288f6c" : "#f08438" } }}
                      style={{ width: "100%", backgroundColor: "white", height: 60 }}
                    >
                      <ReactTab icon={
                        value == 1 ?
                          <img
                            id={'myimg2'}
                            src={ExternalNormal}
                            style={{ height: 25, width: 25, objectFit: "contain", }}
                          />
                          :
                          <img
                            id={'myimg9'}
                            src={ExternalOrange}
                            style={{ height: 25, width: 25, objectFit: "contain", }}
                          />
                      } iconPosition="start" label={this.context.isGerm ? DE.external_projects : EN.external_projects} {...a11yProps(0, value)} />
                      <ReactTab icon={value == 0 ?
                        <img
                          id={'myimg12'}
                          src={INormal}
                          style={{ height: 25, width: 25, objectFit: "contain", }}
                        />
                        :
                        <img
                          id={'myimg13'}
                          src={IGRENN}
                          style={{ height: 25, width: 25, objectFit: "contain", }}
                        />
                      } iconPosition="start" label={this.context.isGerm ? DE.internal_project : EN.internal_project} {...a11yProps(1, value)} />
                    </Tabs>
                  </div>
                  :
                  <></>
                }
              </div> */}
              <div style={{ flex: 1 }}>
                {
                  value == 1 ?
                    this._renderInternal()
                    :
                    this._renderNoraml()
                }
              </div>
              {this.state.loadingArticle && (
                <div className="website_loader">
                  <div className="loader"></div>
                </div>
              )}
              {this.state.openRequestDialog && (
                <ApproveUsers
                  handleClose={(e) => {
                    this.setState({ openRequestDialog: false });
                  }}
                  companyRequests={this.state.companyRequests}
                />
              )}
            </div>
            {this.state.openFilter && (
              <ArticleFilter
                followersValue={this.state.followers}
                followingsValue={this.state.followings}
                handleClose={() => this.setState({ openFilter: false })}
                setParam={this.setParam}
                // handleClick={}
                filter={() => { this.setState({ page: 1, articles: [], hasMore: true });; this.filter() }}
              />
            )}
            {this.state.showRoleDialog && (
              <ArticleRole
                handleClose={() => {
                  this.setState({ showRoleDialog: false });
                }}
                selectSector={this.setSector}
                selectedSector={this.state.sector}
                rolesData={this.state.rolesData}
                setRollData={this.setRollData}
                setParam={this.setParam}
              />
            )}
            {this.props.article && (
              <FeedViewDialog article={this.props.article} />
            )}
          </Animated>
        </Layout>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  const { article, drawerOpen, isExternalSelected, selectedCompanyId, user } = state;
  return {
    article: article,
    drawerOpen: drawerOpen,
    isExternalSelected,
    selectedCompanyId,
    user: user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    viewArticle: (article) => dispatch({ type: ARTICLE, article: article }),
    changeTab: (status) => dispatch({ type: SET_TAB, status: status }),
    setCompanyImage: (status) => dispatch({ type: SET_COMPANY_IMAGE, payload: status }),
    setCompanyId: (id) => dispatch({ type: SET_COMPANY_ID, payload: id })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Articles));
