import React, { useEffect, useState } from 'react';
import { GROUPS, OPEN_CHATS } from "../../store";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { $user } from "../../helper/UserFactory";
import "./SingleChat.css"
import { BASE_URL } from "../../helper/constants";
import { AiOutlineFile, BsCheck, BsCheckAll, BsFillReplyFill } from "react-icons/all";
import { ReactComponent as GoogleMap } from "../../Icons/GoogleMap.svg";
import moment from "moment";
import LanguageContext from '../../helper/LanguageContext';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { urlify } from '../../helper/convertor';
import PlayerFeed from '../../components/PlayerFeed';

const user = $user.get();


function MessageInfo(props) {
    const { isGerm } = React.useContext(LanguageContext);

    const [chat, setChat] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [readByMembers, setReadByMembers] = useState([]);
    const [image, setImage] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [doc, setDoc] = useState('');
    const [viewDoc, setViewDoc] = useState(false);

    const getDetails = async () => {
        const id = props.chatMessage.id;
        const { data } = await callAPI(`/message/${id}/info`, {}, 'GET');
        if (data.status) {
            console.log(data);
            setReadByMembers(data.messageInfo.readByMembers);
            setLoaded(true);
        }
    };


    useEffect(() => {
        getDetails();
        setChat(props.chatMessage);
    }, []);


    return (
        <React.Fragment>
            <div className="w-300 chat_text" style={{ height: "93vh" }}>
                <div className="chat_header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="client_name">
                                <div className="back_number">
                                    <a className="pointer" onClick={props.handleClose}>
                                        <img width={10} src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                                            alt="Generic placeholder" />
                                    </a>
                                </div>
                                <div className="clinet_name_text pl-3 pointer">
                                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                                        {isGerm ? DE.message_info : EN.message_info}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ paddingBottom: 10 }}
                    >
                        <div className="chat_desc pl-3 pr-3" id="chat-sc">
                            <div
                                className={"user_chat_content right_users"}>
                                <div>
                                    {chat.messageType !==
                                        "contact" ? (
                                        <div>
                                            {!chat.isDeleted &&
                                                chat.message !==
                                                "You have blocked this contact" ? (
                                                <div
                                                    className="user_info_chat pb-1"
                                                    id={`chat-${chat.id}`}
                                                >
                                                    {chat.isReply && (
                                                        <div
                                                            className="reply-right pointer"
                                                           
                                                        >
                                                            {chat?.inReplyTo
                                                                ?.fileUrl &&
                                                                chat
                                                                    ?.inReplyTo
                                                                    ?.messageType ==
                                                                "image" ? <></> :
                                                                <p className="m-0 text-primary">
                                                                    {
                                                                        chat?.inReplyTo.senderId !== user?.id ? chat?.inReplyTo.senderName : (isGerm ? DE.you : EN.you)
                                                                    }
                                                                </p>}
                                                            {chat.inReplyTo
                                                                .messageType ===
                                                                "contact" && (
                                                                    <div className="right_msg_reply">
                                                                        <div className="right_reply_img">
                                                                            <img
                                                                                style={{
                                                                                    height: 45,
                                                                                    width: 45,
                                                                                }}
                                                                                src={
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        ?.contacts[0]
                                                                                        ?.avatar
                                                                                        ? BASE_URL +
                                                                                        chat
                                                                                            .inReplyTo
                                                                                            ?.contacts[0]
                                                                                            ?.avatar
                                                                                        : process
                                                                                            .env
                                                                                            .PUBLIC_URL +
                                                                                        "/assets/img/head.svg"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <p
                                                                            className={`m-0 simple-msg-${chat.id}`}
                                                                        >
                                                                            <span className="user-message">
                                                                                {" "}
                                                                                {
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        ?.contacts[0]
                                                                                        .fullName
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            {chat.inReplyTo
                                                                .messageType ===
                                                                "map" && (
                                                                    <div className="row d-block">
                                                                        <div className="col-12">
                                                                            <p className="m-0">
                                                                                {chat.inReplyTo.message.includes(
                                                                                    "Meeting"
                                                                                )
                                                                                    ? "Meeting Point"
                                                                                    : "Location"}
                                                                            </p>
                                                                        </div>
                                                                        <div className="right_reply_img col-12">
                                                                            <GoogleMap
                                                                                style={{
                                                                                    width: 60,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            {chat.inReplyTo
                                                                .messageType ===
                                                                "audio" && (
                                                                    <div className="pointer">
                                                                        <div className="d-flex align-items-center pt-1">
                                                                            <div className="user-avatar">
                                                                                <img
                                                                                    src={
                                                                                        chat
                                                                                            ?.inReplyTo
                                                                                            ?.senderAvatar
                                                                                            ? BASE_URL +
                                                                                            chat
                                                                                                ?.inReplyTo
                                                                                                ?.senderAvatar
                                                                                            : process
                                                                                                .env
                                                                                                .PUBLIC_URL +
                                                                                            "/assets/img/Head-Plain.svg"
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="audio-file">
                                                                                <audio
                                                                                    controls
                                                                                >
                                                                                    <source
                                                                                        src={
                                                                                            chat.local ?
                                                                                                chat.fileUrl[0] :
                                                                                                BASE_URL +
                                                                                                chat
                                                                                                    .inReplyTo
                                                                                                    ?.fileUrl[0]
                                                                                        }
                                                                                        type="audio/mp3"
                                                                                    />
                                                                                </audio>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            {chat.inReplyTo
                                                                .messageType ===
                                                                "video" && (
                                                                    <div className="video-message">
                                                                        <video height="294"
                                                                            controls
                                                                            width="294"
                                                                        >
                                                                            <source
                                                                                src={
                                                                                    BASE_URL +
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        .fileUrl[0]
                                                                                }
                                                                                type="video/webm"
                                                                            ></source>
                                                                        </video>
                                                                    </div>
                                                                )}
                                                            {chat?.inReplyTo
                                                                ?.messageType ===
                                                                "simple" && (
                                                                    <div>
                                                                        <p
                                                                            className={`m-0 simple-reply-msg-${chat.id}`}
                                                                        >
                                                                            <span
                                                                                className="user-message"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        urlify(
                                                                                            chat
                                                                                                .inReplyTo
                                                                                                .message
                                                                                        ),
                                                                                }}
                                                                            />{" "}
                                                                        </p>
                                                                    </div>
                                                                )}

                                                            {chat?.inReplyTo
                                                                ?.fileUrl &&
                                                                chat
                                                                    ?.inReplyTo
                                                                    ?.messageType ==
                                                                "image" ?
                                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                                    <div style={{ width: '22vh', height: "5.5vh" }}>
                                                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                                                            <p style={{ color: "#4693FD", fontSize: 12 }}>
                                                                                {
                                                                                    chat?.inReplyTo.senderId !== user?.id ? chat?.inReplyTo.senderName : (isGerm ? DE.you : EN.you)
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ flexDirection: "row", display: "flex", marginTop: -10 }}>
                                                                            <div>
                                                                                <img
                                                                                    width={"20px"}
                                                                                    height={"20px"}
                                                                                    src={
                                                                                        process.env.PUBLIC_URL +
                                                                                        "/assets/img/add-media.svg"
                                                                                    }
                                                                                    alt="Generic placeholder"
                                                                                    style={{ objectFit: "contain" }}
                                                                                />
                                                                            </div>
                                                                            <p style={{ color: "grey", fontSize: 12, marginTop: 2, marginLeft: 5 }}>{"Photo"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: 1 }}>
                                                                        <img
                                                                            src={
                                                                                chat.local ?
                                                                                    chat.fileUrl[0] :
                                                                                    BASE_URL +
                                                                                    chat
                                                                                        .inReplyTo
                                                                                        ?.fileUrl[0]
                                                                            }
                                                                            style={{ height: 50, width: 50 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                            }

                                                            {chat?.inReplyTo
                                                                ?.fileUrl &&
                                                                chat
                                                                    ?.inReplyTo
                                                                    ?.messageType !==
                                                                "image" && (

                                                                    <div className="right_msg_reply">
                                                                        {chat
                                                                            ?.inReplyTo
                                                                            ?.messageType !==
                                                                            "doc" && (
                                                                                <div className="right_reply_img">
                                                                                    {chat
                                                                                        ?.inReplyTo
                                                                                        ?.messageType ===
                                                                                        "image" ? (
                                                                                        <img
                                                                                            src={
                                                                                                chat.local ?
                                                                                                    chat.fileUrl[0] :
                                                                                                    BASE_URL +
                                                                                                    chat
                                                                                                        .inReplyTo
                                                                                                        ?.fileUrl[0]
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        <p
                                                                            className={`m-0 simple-msg-${chat.id}`}
                                                                        >
                                                                            {chat
                                                                                ?.inReplyTo
                                                                                ?.messageType ===
                                                                                "doc" ? (
                                                                                <span className="user-message">
                                                                                    {" "}
                                                                                    <AiOutlineFile />{" "}
                                                                                    {
                                                                                        chat
                                                                                            .inReplyTo
                                                                                            .attachedFileName
                                                                                    }
                                                                                </span>
                                                                            ) : (
                                                                                <span
                                                                                    className="user-message"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                            urlify(
                                                                                                chat
                                                                                                    .inReplyTo
                                                                                                    .message
                                                                                            ),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )}
                                                  
                                                    {chat.all && chat.all.length > 0 &&
                                                        <div style={{ width: "32vh", height: "20vh", marginBottom: 10, marginRight: 10, marginLeft: 10, marginTop: 5 }}>
                                                            <PlayerFeed
                                                                Links={chat.all}
                                                                article={{}}
                                                            />
                                                        </div>
                                                    }
                                                    {chat.isForward && (
                                                        <p className="color-gray m-0">
                                                            <BsFillReplyFill
                                                                size={15}
                                                                style={{
                                                                    fill: "#cebdbd",
                                                                    marginBottom: 3,
                                                                }}
                                                            />{" "}
                                                            Forwarded
                                                        </p>
                                                    )}
                                                    {chat.messageType ===
                                                        "simple" &&
                                                        chat.message !==
                                                        "You have blocked this contact" ? (
                                                        <div>
                                                            <p
                                                                className={`m-0 simple-msg-${chat.id}`}
                                                            >
                                                                {chat.isDeleted ? (
                                                                    <span>
                                                                        This
                                                                        message
                                                                        has been
                                                                        deleted.
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className="user-message"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                urlify(
                                                                                    chat?.message
                                                                                ),
                                                                        }}
                                                                    />
                                                                )}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {chat.messageType ===
                                                        "image" &&
                                                        !chat.isDeleted ? (
                                                        <div>
                                                            <div
                                                                className="group-ct-image"
                                                                style={{
                                                                    height:
                                                                        parseInt(
                                                                            chat.width
                                                                        ) >
                                                                            parseInt(
                                                                                chat.height
                                                                            )
                                                                            ? 165
                                                                            : parseInt(
                                                                                chat.width
                                                                            ) ===
                                                                                parseInt(
                                                                                    chat.height
                                                                                )
                                                                                ? 294
                                                                                : 294,
                                                                    width:
                                                                        "294px",
                                                                }}
                                                            >
                                                                <img
                                                                    // className="w-100"
                                                                    onClick={() => {
                                                                        setImage(
                                                                            chat.local ?
                                                                                chat
                                                                                    ?.fileUrl[0] :
                                                                                BASE_URL +
                                                                                chat
                                                                                    ?.fileUrl[0]
                                                                        );
                                                                        setShowImage(
                                                                            true
                                                                        );
                                                                    }}
                                                                    style={{ objectFit: 'cover', height: "100%", width: "100%" }}
                                                                    src={
                                                                        chat.local ?
                                                                            chat
                                                                                ?.fileUrl[0] :
                                                                            BASE_URL +
                                                                            chat
                                                                                ?.fileUrl[0]
                                                                    }
                                                                />
                                                            </div>
                                                            <p
                                                                className={`m-0 p-1 simple-msg-${chat.id}`}
                                                            >
                                                                <span
                                                                    className="user-message"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            urlify(
                                                                                chat?.message
                                                                            ),
                                                                    }}
                                                                />
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {chat.messageType ===
                                                        "doc" &&
                                                        !chat.isDeleted ? (
                                                        <div
                                                            className="pointer text-left"
                                                            onClick={() => {
                                                                setDoc(chat);
                                                                setViewDoc(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <AiOutlineFile />{" "}
                                                            {
                                                                chat.attachedFileName
                                                            }
                                                            <p className="m-0 p-1">
                                                                <span
                                                                    className="user-message"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            urlify(
                                                                                chat?.message
                                                                            ),
                                                                    }}
                                                                />
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {chat.messageType ===
                                                        "audio" &&
                                                        !chat.isDeleted ? (
                                                        <div className="pointer">
                                                            <div className="d-flex align-items-center pt-1">
                                                                <div className="user-avatar">
                                                                    <img
                                                                        src={
                                                                            chat.senderAvatar
                                                                                ? BASE_URL +
                                                                                chat.senderAvatar
                                                                                : process
                                                                                    .env
                                                                                    .PUBLIC_URL +
                                                                                "/assets/img/Head-Plain.svg"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="audio-file">
                                                                    <audio
                                                                        controls
                                                                    >
                                                                        <source
                                                                            src={
                                                                                chat.local ?
                                                                                    chat.fileUrl[0] :
                                                                                    BASE_URL +
                                                                                    chat
                                                                                        ?.fileUrl[0]
                                                                            }
                                                                            type="audio/mp3"
                                                                        />
                                                                    </audio>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {chat.messageType ===
                                                        "video" &&
                                                        !chat.isDeleted ? (
                                                        <div className="video-message">
                                                            <video height="294"
                                                                controls
                                                                width="294"
                                                            >
                                                                <source
                                                                    src={
                                                                        BASE_URL +
                                                                        (chat.fileUrl ?
                                                                            chat
                                                                                .fileUrl[0] : "")
                                                                    }
                                                                    type="video/webm"
                                                                ></source>
                                                            </video>
                                                            <p
                                                                className={`m-0 p-1 simple-msg-${chat.id}`}
                                                            >
                                                                <span
                                                                    className="user-message"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            urlify(
                                                                                chat?.message
                                                                            ),
                                                                    }}
                                                                />
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {chat.messageType ===
                                                        "map" &&
                                                        !chat.isDeleted ? (
                                                        <div
                                                            className="map"
                                                            // id={`map-${index}`}
                                                        >
                                                            <p className="m-0 p-1">
                                                                <span className="user-message">
                                                                    {chat.message.includes(
                                                                        "Meeting"
                                                                    )
                                                                        ? "Meeting Point"
                                                                        : "Location"}
                                                                </span>
                                                            </p>
                                                            <div className="">
                                                                <a
                                                                    href={
                                                                        chat.message
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <GoogleMap
                                                                        style={{
                                                                            width: 60,
                                                                        }}
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="clearfix" />
                                                    {!chat.isDeleted && (
                                                        <div className="small">
                                                            {moment(
                                                                chat.createdAt
                                                            ).format(
                                                                "HH:mm"
                                                            )}
                                                            {chat.messageReadByAll ? (
                                                                <BsCheckAll
                                                                    size={15}
                                                                    className="theme-color"
                                                                />
                                                            ) : chat.messageReachedToAll ? (
                                                                <BsCheckAll
                                                                    size={15}
                                                                    color="#737373"
                                                                />
                                                            ) : (
                                                                <BsCheck
                                                                    size={15}
                                                                    color="#737373"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="user_info_chat pb-1">
                                                    <p className="m-0">
                                                        <span>
                                                        {isGerm
                                      ? DE.message_delete
                                      : EN.message_delete}
                                                        </span>
                                                    </p>
                                                    <div className="small">
                                                        {moment(
                                                            chat.createdAt
                                                        ).format("HH:mm")}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {!chat.isDeleted ? (
                                                <div className="contact-section">
                                                    <div className="contact mb-2">
                                                        {chat.contacts &&
                                                            chat.contacts.map(
                                                                (c, i) => (
                                                                    <div
                                                                        className="mb-2 contact-right  p-2"
                                                                        key={i}
                                                                        id={`chat-${chat.id}`}
                                                                    >
                                                                     
                                                                        {chat.isForward && (
                                                                            <p className="color-gray m-0">
                                                                                <BsFillReplyFill
                                                                                    size={
                                                                                        15
                                                                                    }
                                                                                    style={{
                                                                                        fill: "#cebdbd",
                                                                                        marginBottom: 3,
                                                                                    }}
                                                                                />{" "}
                                                                                Forwarded
                                                                            </p>
                                                                        )}
                                                                        <Link
                                                                            to={`/user-profile/${c.id}`}
                                                                        >
                                                                            <div>
                                                                                <span className="contact-name">
                                                                                    {
                                                                                        c.fullName
                                                                                    }
                                                                                </span>
                                                                                <img
                                                                                    className="contact-image"
                                                                                    style={{
                                                                                        borderRadius: 6,
                                                                                    }}
                                                                                    src={
                                                                                        c.avatar
                                                                                            ? BASE_URL +
                                                                                            c.avatar
                                                                                            : process
                                                                                                .env
                                                                                                .PUBLIC_URL +
                                                                                            "/assets/img/head.svg"
                                                                                    }
                                                                                    alt="Generic placeholder"
                                                                                />
                                                                            </div>
                                                                        </Link>
                                                                        <div className="clearfix" />
                                                                        {!chat.isDeleted && (
                                                                            <div className="small">
                                                                                {moment(
                                                                                    chat.createdAt
                                                                                ).format(
                                                                                    "HH:mm"
                                                                                )}
                                                                                {chat.messageReadByAll ? (
                                                                                    <BsCheckAll
                                                                                        size={15}
                                                                                        className="theme-color"
                                                                                    />
                                                                                ) : chat.messageReachedToAll ? (
                                                                                    <BsCheckAll
                                                                                        size={15}
                                                                                        color="#737373"
                                                                                    />
                                                                                ) : (
                                                                                    <BsCheck
                                                                                        size={15}
                                                                                        color="#737373"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="user_info_chat pb-1">
                                                    <p className="m-0">
                                                        <span>
                                                        {isGerm
                                      ? DE.message_delete
                                      : EN.message_delete}s
                                                            been deleted.
                                                        </span>
                                                    </p>
                                                    <div className="small">
                                                        {moment(
                                                            chat.createdAt
                                                        ).format("HH:mm")}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div
                    className={readByMembers.length > 0 ? "message_ready_by row m-0 py-2" : "message_ready_by row m-0 p-0"}>
                    {readByMembers[0]?.isRead &&
                        <div className="col-6 pl-3"><BsCheckAll size={15} className="theme-color" />{" "}{isGerm ? DE.read_by : EN.read_by}</div>}
                    {readByMembers[0]?.isRead && <div
                        className="col-6 pr-3 text-right">{moment(readByMembers[0]?.readDateTime).format('DD.MM.Y HH:mm')}</div>}

                    <div className="col-6 pl-3 "><BsCheckAll size={15} />{" "}{isGerm ? DE.deliver_to : EN.deliver_to}</div>
                    <div className="col-6 pr-3 text-right">
                        {readByMembers[0]?.reachDateTime ? moment(chat.createdAt).format('DD.MM.Y HH:mm') : '---'}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = (state) => {
    const { openChats, groups } = state;
    return {
        groups: groups,
        openChats: openChats,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateGroups: groups => dispatch({ type: GROUPS, groups: groups }),
        updateOpenChatsList: chats => dispatch({ type: OPEN_CHATS, openChats: chats }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageInfo));
